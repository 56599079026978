class Client {
  constructor() {
    this.storageKey = 'my.client';
    this.clientInfo = {
      fifth_element: '',
      name: '',
      email: '',
      phone: '',
      company: '',
      comment: '',
      option_parking_lots: '',
    };
  }

  getClientInfo() {
    if (localStorage && !!localStorage.getItem(this.storageKey)) {
      const storageObj = JSON.parse(localStorage.getItem(this.storageKey));
      this.clientInfo = { ...this.clientInfo, ...storageObj };
    }
    return this.clientInfo;
  }

  updateClientInfo(clientUpdatedInfo) {
    const { name, email, phone, company, comment, option_parking_lots, fifth_element } = clientUpdatedInfo;
    document.cookie = 'client_email=' + email + '; path=/; expires=' + new Date(2147483647 * 1000).toUTCString();

    if (localStorage) {

      const updatedClientObj = {
        ...this.clientInfo,
        ...{ name, email, phone, company, comment, option_parking_lots, fifth_element },
      };
      let clientInfoString = JSON.stringify(updatedClientObj);
      localStorage.setItem(this.storageKey, clientInfoString);
    }
  }

  getI18nAttrNames() {
    return {
      nameI18n: I18n.t('generic.name'),
      emailI18n: I18n.t('generic.email'),
      companyI18n: I18n.t('generic.company'),
      phoneI18n: I18n.t('generic.phone'),
      dateI18n: I18n.t('generic.date'),
      messageI18n: I18n.t('generic.message'),
    };
  }

  getI18nAttrNamesMobileModalForm() {
    return {
      fullName: I18n.t('generic.full_name'),
      emailI18n: I18n.t('generic.email'),
      companyName: I18n.t('generic.company_name'),
      phoneNumber: I18n.t('activemodel.attributes.provider_service_request.phone_number_order_form'),
      dateI18n: I18n.t('generic.date'),
      messageI18n: I18n.t('generic.message'),
    };
  }

  setCountryPhone() {}
}

export default new Client();
