import React from 'react';

import { arrayIncludesObject } from '../helpers/array';

import { SvgIco } from "./Icons";

import './DropdownSelector.sass';

var typingTimer;

class DropdownPlainSelector extends React.PureComponent {
  state = {
    isOpened: this.props.isOpened || false,
    searchIsEnabled: this.props.enableSearch || false,
    columns: this.props.columns || 1,
    searchValue: '',
    loadingIsStarted: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.dynamicData !== prevProps.dynamicData
      && this.state.loadingIsStarted
      && this.setState({ loadingIsStarted: false });
  }

  handleClickOutside = (event) => {
    if (event.target.tagName !== 'svg' && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isOpened: false,
        searchValue: '',
        loadingIsStarted: false,
      })
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  itemIsSelected = (id) => {
    return arrayIncludesObject(this.props.selectedData, 'id', id);
  };

  handleSearchFocus = (e) => {
    this.setState({
      isOpened: true
    })
  };

  changeSearchValue = e => {
    let emptyString = e.target.value.length === 0;

    this.setState({
      ...this.state,
      searchValue: e.target.value,
      isOpened: !emptyString,
      loadingIsStarted: !emptyString,
    })
  };

  handleClick = (e) => {
    this.setState({
      ...this.state,
      isOpened: !this.state.isOpened
    });
  };

  handleSearchKeyUp = (e) => {
    if(e.target.value.length === 0) return false;

    clearTimeout(typingTimer);
    typingTimer = setTimeout(this.doneTyping, 2000);
  };

  handleSearchKeyDown = (e) => {
    clearTimeout(typingTimer);
  };

  doneTyping = () => {
    this.props.searchAction(this.state.searchValue);
  };

  renderPlaceholder = () => {
    let placeholder;
    switch(this.props.selectedData.length) {
      case 0:
        placeholder = this.props.placeholder;
        break;
      case 1:
        placeholder = this.props.selectedData[0].name.charAt(0).toUpperCase() + this.props.selectedData[0].name.slice(1);
        break;
      default:
        placeholder = `${this.props.selectedCounterPhrase}: (${this.props.selectedData.length})`;
    }
    return placeholder;
  };

  closeDropdown = e => {
    this.setState({
      ...this.state,
      isOpened: false,
      searchValue: '',
      loadingIsStarted: false,
    })
  };

  generateDropdownBody = (itemsInColumn, columns, itemsArray) => {
    let items = itemsArray && itemsArray.length ? [...itemsArray] : [];
    let newArray = [];

    for(var i = 0, len = columns; i < columns; i++) {
      newArray.push([]);
      for(var y = 0, count = itemsInColumn; y < count; y++) {
        if(items.length) newArray[i].push(items.shift())
      }
    }

    const dropdownBody = newArray.map((subMenu, index) => {
      return (
        <div className='dropdown-selector__column' key={index}>
          {subMenu.map(item => {
            return (
              <div key={item.id}
                onClick={e => this.props.selectAction(e, item)}
                className={`dropdown-selector__item ${ this.itemIsSelected(item.id) ? 'dropdown-selector__item--selected' : '' }`}>

                <div className='dropdown-selector__item-icon'>
                  <div className={ this.itemIsSelected(item.id) ? 'dropdown-selector__item-icon-checked' : 'dropdown-selector__item-checkbox' }>
                    <SvgIco name="check" size={16} />
                  </div>
                </div>

                <div className='dropdown-selector__item-name' style={{textTransform: 'capitalize'}}>
                  {item.name}
                </div>
              </div>
            )
          })}
        </div>
      )
    });

    return dropdownBody;

  };

  render() {
    const { isOpened, searchIsEnabled, loadingIsStarted } = this.state;
    const { dynamicData, placeholder, selectedData } = this.props;
    const itemsInColumn = dynamicData && dynamicData.length ? Math.ceil(dynamicData.length / this.state.columns) : 0;
    const dropdownBody = this.generateDropdownBody(itemsInColumn, this.state.columns, dynamicData);

    return (
      <div className='dropdown-selector' ref={ this.setWrapperRef }>

        {(!searchIsEnabled) && (
          <div className={`dropdown-selector__trigger ${selectedData.length > 0 ? 'dropdown-selector__trigger--active': '' }`} onClick={e => this.handleClick(e)}>
            <div className='dropdown-selector__placeholder'>{ this.renderPlaceholder() }</div>
            <div className={`dropdown-selector__action-icon dropdown-selector__action-icon--colored${isOpened ? ' open' : ''}`}>
              { isOpened
                ? <SvgIco name="expand-less" size={24} />
                : <SvgIco name="expand-more" size={24} />
              }
            </div>
          </div>
        )}

        { searchIsEnabled &&
          <div className='dropdown-selector__trigger dropdown-selector__search'>
            <div className='dropdown-selector__placeholder'>
              <input
                type='text'
                value={this.state.searchValue}
                onKeyUp={e => this.handleSearchKeyUp(e) }
                onKeyDown={e => this.handleSearchKeyDown(e) }
                onChange={e => this.changeSearchValue(e)}
                placeholder={placeholder}
                onFocus={e => this.handleSearchFocus(e)} />
            </div>
            <div className='dropdown-selector__action-icon search' onClick={this.closeDropdown}>
              { !this.state.isOpened && <SvgIco name="search" size={24} /> }
              { this.state.isOpened && <SvgIco name="close" size={24} />}
            </div>
          </div>
        }

        {(searchIsEnabled) && (
          <div>
            {isOpened && (
              <div className='dropdown-selector__body'>
                {(!loadingIsStarted && dynamicData.length > 0 && this.state.searchValue.length > 0) && dropdownBody}
                {loadingIsStarted && (<div className='lds-ellipsis'><div /><div /><div /><div /></div>)}
                {(!loadingIsStarted && (dynamicData.length === 0 && this.state.searchValue.length !== 0)) && (
                  <div className='dropdown-selector__empty'>
                    {I18n.t('search_agent.no_results')}
                  </div>)}
              </div>
            )}
          </div>
        )}

        {(!searchIsEnabled) && (
          <div>
            {isOpened && (
              <div className='dropdown-selector__body'>
                {(dynamicData.length > 0) && dropdownBody}
                {(dynamicData.length === 0) && (
                  <div className='dropdown-selector__empty'>
                    {I18n.t('search_agent.no_results')}
                  </div>)}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DropdownPlainSelector;
