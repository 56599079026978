import React from "react";

const Link = ({ className, href, children }) => {
  // prevent full page reload
  const onClick = (event) => {
    if (!event.ctrlKey && !event.metaKey) {
      event.preventDefault();

      const isIE = navigator.userAgent.indexOf('MSIE') >= 0 || navigator.userAgent.indexOf('Trident/') >= 0;

      if (isIE) {
        window.location.href = href;
      } else {
        window.history.pushState({}, "", href);
        window.dispatchEvent(new PopStateEvent('popstate'));
      }
    }
  };

  return (
    <a className={className} href={href} onClick={onClick}>
      {children}
    </a>
  );
};

export default Link;
