import React, { Component } from 'react';
import "core-js/stable";
import "regenerator-runtime/runtime";
import update from 'immutability-helper';
import axios from 'axios';
import scrollToComponent from 'react-scroll-to-component';
import Sticky from 'react-stickynode';
import queryString from "query-string";

import Client from '../../../utils/Client';
import { isMobile } from '../../../utils/helpers';
import { apiProvider } from '../../../api/core';
import { getObjectIndexInArray, arrayIncludesObject } from '../../helpers/array';
import Tooltip from '../../SharedComponents/Tooltip';
import RangeSelector from '../../SharedComponents/RangeSelector';
import { RadioInput } from '../../SharedComponents/Inputs';
import DataProtectionModal from "../../SharedComponents/DataProtectionModal";
import { TagsList } from '../../SharedComponents/TagsList';
import { SvgIco } from '../../SharedComponents/Icons';
import AreaSelector from './AreaSelector';
import TypeSelector from './TypeSelector';
import SectionSelector from './SectionSelector';
import ContactForm from './ContactForm';
import UnsubscribeModal from './UnsubscribeModal';

import './SearchAgentConfigurations.sass';

const squareMetersPerPerson = 15;

class SearchAgentConfigurations extends Component {
  state = {
    isModalOpen: this.props.isDeletingModalOpen,
    formMethod: (this.props.isEditMode ? 'PATCH' : 'POST'),
    formUrl: (this.props.isEditMode ? this.props.endPoints.base + '/' + this.props.searchAgentParams.access_token : this.props.endPoints.base) + '.json',
    isEditMode: this.props.isEditMode,
    overviewIsOpened: false,
    locale: this.props.locale,
    isSaleAgent: this.props.isSaleAgent || (this.props.searchAgentParams && this.props.searchAgentParams.sections.indexOf('lease') == -1),
    isDK: this.props.siteKey === 'dk',
    areaError: false,
    typeError: false,
    sectionError: false,
    contactsError: false,

    sections: Object.assign({}, this.props.sections),
    dynamicSections: Object.assign({}, this.props.sections),
    selectedSections: [],

    searchAreas: [],
    dynamicSearchAreas: [],

    areas: [],
    dynamicAreas: [],
    selectedAreas: [],
    currentArea: undefined,

    path: [],

    propertyTypes: Object.assign({}, this.props.types),
    dynamicPropertyTypes: Object.assign({}, this.props.types),
    selectedPropertyTypes: [],

    priceCurrencies: [
      {
        id: 1,
        type: 'month',
        title: `${I18n.t('search_agent.configuration.price_currency_1')}`,
        min: 0,
        max: this.props.keywords.monthlyMaxPrice,
      },
      {
        id: 2,
        type: 'year',
        title: `${I18n.t('search_agent.configuration.price_currency_2')}`,
        min: 0,
        max: this.props.keywords.yearlyPerM2MaxPrice,
      },
      {
        id: 3,
        hidden: true,
        type: 'sales',
        title: `${I18n.t('search_agent.configuration.price_currency_3')}`,
        min: 0,
        max: this.props.keywords.saleMaxPrice,
      }
    ],
    selectedPriceCurrency: {},

    minPrice: 0,
    maxPrice: 1,
    selectedPrice: {
      min: 0,
      max: 1,
    },
    priceIsPristine: true,

    showAreaRangeAsM2: !this.props.searchAgentParams || this.props.searchAgentParams.size_range_type === 'm2' ? true : false,
    minAreaSize: 0,
    maxAreaSize: this.props.keywords.maxArea,
    selectedAreaSize: {
      min: 0,
      max: this.props.keywords.maxArea,
    },
    sizeIsPristine: true,

    minEmployees: 0,
    maxEmployees: 1,
    selectedEmployees: {
      min: 0,
      max: 1,
    },
    employeesIsPristine: true,

    user: {
      email: '',
      name: '',
      company: '',
      phone: '',
      errors: {}
    },

    isButtonDisabled: false,
    isShowingModal: false,
    userFormHasBeenUpdated: false,
  };

  toggleModal = (e) => {
    e.stopPropagation();
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  setInitialState() {

    const { siteKey, allCountries, types } = this.props;
    const getPath = queryString.parse(window.location.search);
    const reformatedTypes = this.prepareTypes(types);
    const { area_max, area_min, rent_max, rent_min } = getPath;
    const queryTypes = getPath['kinds[]'] ? getPath['kinds[]'].split(',') : null;
    const querySections = getPath['sections[]'] ? getPath['sections[]'].split(',') : null;;
    const preselectedTypes = [];

    let searchAgentParams = this.props.searchAgentParams || {};
    let areaSizeMin = searchAgentParams.size_range_from || this.state.selectedAreaSize.min;
    let areaSizeMax = searchAgentParams.size_range_to || this.state.selectedAreaSize.max;
    let maxEmployeesMax = Math.floor(this.state.maxAreaSize / squareMetersPerPerson);
    let defaultEmployeesCountMin = Math.floor(areaSizeMin / squareMetersPerPerson);
    let defaultEmployeesCountMax = Math.floor(areaSizeMax / squareMetersPerPerson) > 1
      ? Math.floor(areaSizeMax / squareMetersPerPerson)
      : 1;

    let defaultSelectedPriceMin = this.state.selectedPrice.min;
    let selectedPriceMin = searchAgentParams.monthly_price_range_from || searchAgentParams.yearly_price_range_per_m2_from || searchAgentParams.sale_price_range_from;
    let defaultSelectedPriceMax = this.state.isSaleAgent ? this.state.priceCurrencies[2].max : this.state.priceCurrencies[1].max;
    let selectedPriceMax = searchAgentParams.monthly_price_range_to || searchAgentParams.yearly_price_range_per_m2_to || searchAgentParams.sale_price_range_to;

    let sizeIsPristine = !(area_max || area_max || searchAgentParams.size_range_from || searchAgentParams.size_range_to);
    let priceIsPristine = !(rent_min || rent_max || selectedPriceMin || selectedPriceMax);
    let employeesIsPristine = sizeIsPristine;
    const { name, phone, email, company } = Client.getClientInfo();
    const savedFromEmail = searchAgentParams.client ? searchAgentParams.client.email : '';
    const currentCountry = siteKey !== 'global' && allCountries.filter(country => country.data.alpha2.toLowerCase() === siteKey)[0].data.name;

    queryTypes && queryTypes.length && reformatedTypes.forEach(type => queryTypes.indexOf(type.id) >= 0 && preselectedTypes.push(type));

    this.setState({
      ...this.state,
      selectedPriceCurrency: this.state.isSaleAgent
        ? this.state.priceCurrencies[2]
        : (searchAgentParams.monthly_price_range_from || searchAgentParams.monthly_price_range_to ? this.state.priceCurrencies[0] : this.state.priceCurrencies[1]),
      maxPrice: this.state.isSaleAgent
        ? this.state.priceCurrencies[2].max
        : (searchAgentParams.monthly_price_range_from || searchAgentParams.monthlyPriceRangeTo ? this.state.priceCurrencies[0].max : this.state.priceCurrencies[1].max),
      selectedPrice: {
        ...this.state.selectedPrice,
        min: Number(rent_min) || selectedPriceMin || defaultSelectedPriceMin,
        max: Number(rent_max) || selectedPriceMax || defaultSelectedPriceMax,
      },
      priceIsPristine: priceIsPristine,
      selectedAreaSize: {
        ...this.state.selectedAreaSize,
        min: Number(area_min) || searchAgentParams.size_range_from || this.state.selectedAreaSize.min,
        max: Number(area_max) || searchAgentParams.size_range_to || this.state.selectedAreaSize.max,
      },
      sizeIsPristine: sizeIsPristine,
      maxEmployees: maxEmployeesMax,
      selectedEmployees: {
        ...this.state.selectedEmployees,
        min: defaultEmployeesCountMin,
        max: defaultEmployeesCountMax,
      },
      employeesIsPristine: employeesIsPristine,
      selectedSections: this.prepareSections(searchAgentParams.sections || querySections || this.state.selectedSections),
      selectedPropertyTypes: (preselectedTypes.length && preselectedTypes) || searchAgentParams.kinds_with_names || this.state.selectedPropertyTypes,
      selectedAreas: searchAgentParams.postal_districts_with_names || this.state.selectedAreas,
      isSaleAgent: this.props.isSaleAgent || (this.props.searchAgentParams && this.props.searchAgentParams.sections.indexOf('lease') == -1),
      formUrl: (this.props.isEditMode ? this.props.endPoints.base + '/' + this.props.searchAgentParams.access_token : this.props.endPoints.base) + '.json',
      user: {
        ...this.state.user,
        name: searchAgentParams.person_name ? searchAgentParams.person_name : name,
        email: savedFromEmail ? savedFromEmail : email,
        company: searchAgentParams.company_name ? searchAgentParams.company_name : company,
        phone: searchAgentParams.phone_number ? searchAgentParams.phone_number : phone.length > 6 ? phone : '',
      },
      path: this.props.siteKey === 'global' ? [ {name: 'Continents'} ] : [{name: currentCountry}],
    })
  }

  createSearchAgent = () => {
    if(!this.searchAgentIsFilled()) return;

    const data = new FormData();
    const { isEditMode, searchLeasesLink, locale, token } = this.props;

    data.append("[search_agent][locale]", this.state.locale);

    data.append("[search_agent][person_name]", this.state.user.name);
    data.append("[search_agent][company_name]", this.state.user.company);
    data.append("[search_agent][phone_number]", this.state.user.phone);
    data.append("[search_agent_client][email]", this.state.user.email);

    data.append("[search_agent][size_range_from]", this.state.selectedAreaSize.min);
    data.append("[search_agent][size_range_to]", this.state.selectedAreaSize.max);
    data.append("[search_agent][size_range_type]", this.state.showAreaRangeAsM2 ? 'm2' : 'number_of_employees');

    if(this.state.selectedAreas.length) {
      this.state.selectedAreas.forEach(area => {
        data.append("[search_agent][postal_district_ids][]", area.id);
      })
    }

    this.state.selectedPropertyTypes.forEach(type => {
      data.append("[search_agent][kinds][]", type.id);
    });

    if(!this.state.isSaleAgent && this.state.isDK) {
      if(this.state.selectedPriceCurrency.type == 'year') {
        data.append("[search_agent][yearly_price_range_per_m2_from]", this.state.selectedPrice.min);
        data.append("[search_agent][yearly_price_range_per_m2_to]", this.state.selectedPrice.max);
      } else if(this.state.selectedPriceCurrency.type == 'month') {
        data.append("[search_agent][monthly_price_range_from]", this.state.selectedPrice.min);
        data.append("[search_agent][monthly_price_range_to]", this.state.selectedPrice.max);
      }
    } else if(this.state.isSaleAgent && this.state.isDK) {
      data.append("[search_agent][sale_price_range_from]", this.state.selectedPrice.min);
      data.append("[search_agent][sale_price_range_to]", this.state.selectedPrice.max);
    }

    if(!this.state.isSaleAgent) {
      data.append("[search_agent][sections][]", 'lease');
    } else {
      this.state.selectedSections.forEach(section => {
        data.append("[search_agent][sections][]", section.id);
      })
    }

    axios({method: this.state.formMethod, url: this.state.formUrl, data: data})
      .then(response => {
        const notificationAction = isEditMode ? 'edit' : 'create';
        const editedToken = isEditMode ? this.props.searchAgentParams.access_token : false;
        const urlIncludesLocale = searchLeasesLink.length > 1;
        const href = `${urlIncludesLocale ? searchLeasesLink : ''}/${I18n.t('routes.search_agents')}?site=${locale}&token=${response.data.access_token || token}&create=success`;

        this.props.showNotification(true, notificationAction, editedToken);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
          { 'event' : 'search-agent-form-sent',
            'eventCategory': 'search-agent-form-category',
            'eventAction': 'sent-agent-form-data-push'
          }
        );
        this.props.handleRedirect(href);
      })
      .catch(error => console.log(error));

    this.setState({isButtonDisabled: true});
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.searchAgentParams !== prevProps.searchAgentParams && this.setInitialState();
  }

  backAction = (e) => {
    delete this.state.path[this.state.path.length-1].name;
    this.setState({
      ...this.state,
      dynamicAreas: Object.assign({}, this.state.path[this.state.path.length - 1]),
      path: update(this.state.path, {$splice: [[this.state.path.length - 1, 1]] }),
      currentArea: Object.assign({}, this.state.path[this.state.path.length - 2])
    });
  };

  toggleOverview = () => {
    if(!isMobile()) return;
    this.setState({
      ...this.state,
      overviewIsOpened: !this.state.overviewIsOpened,
    })
  };

  selectAllAreas = (e) => {
    let selectedArray = [];
    Object.keys(this.state.dynamicAreas).forEach(key => {
      if(!arrayIncludesObject(this.state.selectedAreas, 'id', this.state.dynamicAreas[key].id)) {
        selectedArray.push(this.state.dynamicAreas[key]);
      }
    });
    this.setState({
      ...this.state,
      selectedAreas: [...this.state.selectedAreas, ...selectedArray],
      areaError: false,
    })
  };

  deselectAllAreas = (e) => {
    let newSelectedArray = [...this.state.selectedAreas];
    Object.keys(this.state.dynamicAreas).forEach(key => {
      const index = getObjectIndexInArray(newSelectedArray, 'id', this.state.dynamicAreas[key].id);
      index >= 0 && newSelectedArray.splice(index, 1);
    });

    this.setState({
      ...this.state,
      selectedAreas: [...newSelectedArray]
    });
  };

  selectPropertyType = (e, item) => {
    const index = getObjectIndexInArray(this.state.selectedPropertyTypes, 'id', item.id),
      itemIsSelected = index != -1;

    this.setState({
      ...this.state,
      typeError: false,
      selectedPropertyTypes: (itemIsSelected
        ? update(this.state.selectedPropertyTypes, {$splice: [[index, 1]] })
        : update(this.state.selectedPropertyTypes, {$push: [item]}))
    })
  };

  selectSection = (e, item) => {
    const index = getObjectIndexInArray(this.state.selectedSections, 'id', item.id),
      itemIsSelected = index != -1;

    this.setState({
      ...this.state,
      sectionError: false,
      selectedSections: (itemIsSelected
        ? update(this.state.selectedSections, {$splice: [[index, 1]] })
        : update(this.state.selectedSections, {$push: [item]}))
    })
  };

  selectSearchArea = (e, item) => {
    const index = getObjectIndexInArray(this.state.selectedAreas, 'id', item.id),
      itemIsSelected = index !== -1;

    this.setState({
      ...this.state,
      areaError: false,
      selectedAreas: (itemIsSelected
        ? update(this.state.selectedAreas, {$splice: [[index, 1]] })
        : update(this.state.selectedAreas, {$push: [item]}))
    })
  };

  selectArea = (e, item) => {
    if(item.countries) {
      this.setState({
        ...this.state,
        path: [...this.state.path, { ...this.state.dynamicAreas, name: item.name }],
        currentArea: item,
        dynamicAreas: { ...item.countries },
      })
    } else if(item.provinces) {
      this.setState({
        ...this.state,
        path: [...this.state.path, {...this.state.dynamicAreas, name: item.name}],
        currentArea: item,
        dynamicAreas: { ...item.provinces },
      })
    } else if(item.postal_districts) {
      this.setState({
        ...this.state,
        path: [...this.state.path, {...this.state.dynamicAreas, name: item.name}],
        currentArea: item,
        dynamicAreas: {...item.postal_districts},
      })
    } else {
      const index = getObjectIndexInArray(this.state.selectedAreas, 'id', item.id),
        itemIsSelected = index != -1;

      this.setState({
        ...this.state,
        areaError: false,
        selectedAreas: (itemIsSelected
          ? update(this.state.selectedAreas, {$splice: [[index, 1]] })
          : update(this.state.selectedAreas, {$push: [item]}))
      })
    }
  };

  handleBreadcrumbClick = (index) => {

    const newDynamicAreas = this.state.path[index + 1];

    delete newDynamicAreas.name;

    this.setState({
      ...this.state,
      dynamicAreas: Object.assign({}, newDynamicAreas),
      path: this.state.path.slice(0, index + 1),
      currentArea: Object.assign({}, this.state.path[index])
    });
  };

  removeSearchAreaTag = (e, id) => {
    const index = getObjectIndexInArray(this.state.selectedAreas, 'id', id);

    this.setState({
      ...this.state,
      selectedAreas: update(this.state.selectedAreas, {$splice: [[index, 1]] })
    })
  };

  removeAreaTag = (e, id) => {
    const index = getObjectIndexInArray(this.state.selectedAreas, 'id', id);

    this.setState({
      ...this.state,
      selectedAreas: update(this.state.selectedAreas, {$splice: [[index, 1]] }),
      path: [],
      dynamicAreas: Object.assign({}, this.state.areas),
      currentArea: undefined,
    })
  };

  removeTypeTag = (e, id) => {
    const index = getObjectIndexInArray(this.state.selectedPropertyTypes, 'id', id);

    this.setState({
      ...this.state,
      selectedPropertyTypes: update(this.state.selectedPropertyTypes, {$splice: [[index, 1]] })
    })
  };

  removeSectionTag = (e, id) => {
    const index = getObjectIndexInArray(this.state.selectedSections, 'id', id);

    this.setState({
      ...this.state,
      selectedSections: update(this.state.selectedSections, {$splice: [[index, 1]] })
    })
  };

  changePriceCurrency = (e, item) => {
    this.setState({
      ...this.state,
      selectedPriceCurrency: item,
      minPrice: item.min,
      maxPrice: item.max,
      selectedPrice: {
        min: item.min,
        max: item.max
      }
    })
  };

  handlePriceSelectorChange = (value) => {
    this.setState({
      ...this.state,
      priceIsPristine: false,
      selectedPrice: value,
      maxPrice: value.max > this.state.maxPrice ? value.max : this.state.maxPrice
    })
  };

  handleAreaSizeSelectorChange = (value) => {
    if (value) {
      const employeesCountMax = Math.floor(value.max / squareMetersPerPerson);
      const employeesCountMin = Math.floor(value.min / squareMetersPerPerson);

      this.setState({
        ...this.state,
        sizeIsPristine: false,
        employeesIsPristine: false,
        selectedAreaSize: value,
        maxAreaSize: value.max > this.state.maxAreaSize
          ? value.max
          : this.state.maxAreaSize,
        maxEmployees: employeesCountMax > this.state.maxEmployees
          ? employeesCountMax
          : this.state.maxEmployees,
        selectedEmployees: {
          min: employeesCountMin > 1 ? employeesCountMin : 0,
          max: employeesCountMax > 1 ? employeesCountMax : 1,
        }
      });
    }
  };

  handleEmployeesSelectorChange = (value) => {
    if (value) {
      const areaSizeCountMax = Math.floor(value.max * squareMetersPerPerson);
      const areaSizeCountMin = Math.floor(value.min * squareMetersPerPerson);

      this.setState({
        ...this.state,
        sizeIsPristine: false,
        employeesIsPristine: false,
        selectedEmployees: value,
        maxEmployees: value.max > this.state.maxEmployees
          ? value.max
          : this.state.maxEmployees,
        maxAreaSize: areaSizeCountMax > this.state.maxAreaSize
          ? areaSizeCountMax
          : this.state.maxAreaSize,
        selectedAreaSize: {
          min: areaSizeCountMin,
          max: areaSizeCountMax,
        }
      });
    }
  };

  resetPriceSelector = () => {
    this.setState({
      ...this.state,
      priceIsPristine: true,
      selectedPrice: {
        min: this.state.minPrice,
        max: this.state.maxPrice,
      }
    })
  };

  resetAreaSizeSelector = () => {
    this.setState({
      ...this.state,
      sizeIsPristine: true,
      employeesIsPristine: true,
      selectedAreaSize: {
        min: this.state.minAreaSize,
        max: this.state.maxAreaSize,
      },
      selectedEmployees: {
        min: this.state.minEmployees,
        max: this.state.maxEmployees,
      }
    })
  };

  updateErrors = errors => {
    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        errors: {...errors}
      }
    })
  };

  updateUser = values => {

    const newValues = { ...values };

    if (newValues.phone && newValues.phone.length < 6) newValues.phone = '';

    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        ...newValues
      }
    })
  };

  areaSearchAction = value => {

    apiProvider.getAll(`${this.props.endPoints.postalDistricts.searchByName}?query=${value}`)
      .then(response => this.setState({ ...this.state, dynamicSearchAreas: [...response] }));
  };

  decamelize = (string) => {
    return string.split(/(?=[A-Z])/).join('_').toLowerCase();
  };

  prepareTypes = (array) => {
    return Object.keys(array).map(key => {
      return {
        id: this.decamelize(key),
        name: array[key]
      }
    })
  };

  prepareSections = (array) => {
    return array.map(key => {
      return {
        id: key,
        name: `${I18n.t("generic.models.property.category." + key, {'count': 1})}`
      }
    })
  };

  searchAgentIsFilled = () => {
    let areaError,
      typeError,
      sectionError,
      contactsError;

    let scrollTo;
    let user = this.state.user;
    if(Object.keys(user.errors).length > 0) {
      contactsError = true;
    } else {
      if(!user.name || !user.email || !user.phone || !user.company) {
        contactsError = true;
        scrollTo = 'contacts';
      } else {
        contactsError = false;
      }
    }

    if(this.state.isSaleAgent) {
      if(this.state.selectedSections.length === 0) {
        sectionError = true;
        scrollTo = 'section';
      } else {
        sectionError = false;
      }
    }

    if(this.state.selectedPropertyTypes.length === 0) {
      typeError = true;
      scrollTo = 'type';
    } else {
      typeError = false;
    }

    if(this.state.selectedAreas.length === 0) {
      areaError = true;
      scrollTo = 'area';
    } else {
      areaError = false;
    }

    this.setState({
      ...this.state,
      areaError: areaError,
      typeError: typeError,
      sectionError: sectionError,
      contactsError: contactsError,
      overviewIsOpened: this.state.overviewIsOpened && !areaError && !typeError && !sectionError && !contactsError
    });

    if(scrollTo) this.handleError(scrollTo);

    return !areaError && !typeError && !sectionError && !contactsError;
  };

  handleError = (fieldName) => {
    scrollToComponent(this[`${fieldName}Ref`], {
      offset: -60,
      align: 'top',
      ease: 'linear',
      duration: 300
    });
  };

  showOverviewButtonOnMobile = () => {
    if(!isMobile()) return false;
    let s = this.state;

    if(s.selectedSections.length > 0) return true;
    if(s.selectedAreas.length > 0) return true;
    if(s.selectedPropertyTypes.length > 0) return true;
    if(!s.priceIsPristine || !s.sizeIsPristine || !s.employeesIsPristine) return true;
    if(s.user.email || s.user.name || s.user.company || s.user.phone) return true;

    return false;
  };

  checkAllErrors = () => {
    let s = this.state;
    const saleCheck = s.isSaleAgent ? s.selectedSections.length > 0 : true;
    const userCheck = s.user.email.length > 0 && s.user.name.length > 0 && s.user.company.length > 0 && s.user.phone.length > 0;

    if (
      saleCheck
      && s.selectedAreas.length > 0
      && s.selectedPropertyTypes.length > 0
      && (Object.keys(s.user.errors).length === 0)
      && userCheck
    ) return true
  };

  setPostalDistrictsFromSearchId = (areasData) => {

    const { preselectedPostalDistricts, siteKey } = this.props;
    let selectedAreas = [];

    const mapPostalDistricts = (postalDistricts) =>
      postalDistricts.map(postalDistrict => preselectedPostalDistricts.includes(postalDistrict.id) && selectedAreas.push(postalDistrict));

    siteKey === 'global'
      ? Object.entries(areasData).map(([key, area]) => {
          Object.entries(area.countries).map(([key, country]) => {
            Object.entries(country.provinces).map(([key, province]) => mapPostalDistricts(province.postal_districts))
          })
        }
      )
      : Object.entries(areasData).map(([key, province]) => mapPostalDistricts(province.postal_districts));

    selectedAreas.length && this.setState({ ...this.state, selectedAreas: selectedAreas });
  };

  componentDidMount() {
    const { preselectedPostalDistricts } = this.props;

    this.setInitialState();
    window.dataLayer = window.dataLayer || [];

    apiProvider.getAll(this.props.endPoints.areasTree)
      .then(response => {

        preselectedPostalDistricts && preselectedPostalDistricts.length && this.setPostalDistrictsFromSearchId(response);

        this.setState({
          ...this.state,
          areas: Object.assign({}, response),
          dynamicAreas: Object.assign({}, response),
        });
      });
  }

  openInfoModal = () => {
    this.setState({ isShowingModal: !this.state.isShowingModal });
  };

  closeInfoModal = () => {
    this.setState({ isShowingModal: false });
  };

  handleFormUpdate = () => this.setState({ userFormHasBeenUpdated: true });

  render() {
    const {
      dynamicSearchAreas,
      selectedSections,
      isSaleAgent,
      areaError,
      typeError,
      sectionError,
      contactsError,
      dynamicAreas,
      selectedAreas,
      path,
      currentArea,
      selectedPropertyTypes,
      selectedPrice,
      selectedPriceCurrency,
      selectedAreaSize,
      selectedEmployees,
      priceIsPristine,
      sizeIsPristine,
      employeesIsPristine,
      overviewIsOpened,
      isDK,
      user,
      isEditMode,
      isModalOpen,
      userFormHasBeenUpdated,
      showAreaRangeAsM2,
    } = this.state;

    const reformatedTypes = this.prepareTypes(this.props.types);
    const reformatedSections = this.prepareSections(this.props.sections);
    const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);
    const locale = I18n.locale === 'en' ? 'fr' : I18n.locale;
    const localizedNumberFormatter = (value) => new Intl.NumberFormat(locale).format(value);

    const overviewBlock = (
      <div className={`search-agent__overview-container ${overviewIsOpened ? 'search-agent__overview-opened' : ''}`} ref={node => this.overviewContainer = node}>
        <div className='search-agent__overview-header' onClick={this.toggleOverview}>
          <h2>{I18n.t('search_agent.overview.title')}</h2>
          { isMobile() && (
            <span>
              { overviewIsOpened
                ? <SvgIco name="close" size={24} />
                : <SvgIco name="expand-less" size={24} />
              }
            </span>
          )}
        </div>
        <div className='search-agent__overview-body'>

          <div className="search-agent__overview-description mobile-hidden">
            <p>{I18n.t('search_agent.overview.description.desktop')}</p>
          </div>

          <div className="search-agent__overview-description">
            <p>{I18n.t('search_agent.overview.description.mobile')}</p>
          </div>

          <div className='search-agent__overview-section'>
            <h3>{I18n.t('search_agent.area')}</h3>

            {(!selectedAreas.length) && (
              <p>{I18n.t('search_agent.overview.empty_area')}</p>
            )}

            <TagsList tags={selectedAreas} removeTag={this.removeAreaTag} />
          </div>

          <div className='search-agent__overview-section'>
            <h3>{I18n.t('search_agent.type')}</h3>

            {(!selectedPropertyTypes.length) && (
              <p>{I18n.t('search_agent.overview.empty_type')}</p>
            )}

            <TagsList tags={selectedPropertyTypes} removeTag={this.removeTypeTag} />
          </div>

          {isSaleAgent && (<div className='search-agent__overview-section'>
            <h3>{I18n.t('search_agent.section')}</h3>

            {(!selectedSections.length) && (
              <p>{I18n.t('search_agent.overview.empty_section')}</p>
            )}

            <TagsList tags={selectedSections} removeTag={this.removeSectionTag} />
          </div>)}

          {isDK && (
            <div className='search-agent__overview-section'>
              <h3>{I18n.t('search_agent.price')}</h3>

              {priceIsPristine && (
                <p>{I18n.t('search_agent.overview.empty_price')}</p>
              )}

              { !priceIsPristine && (
                <ul className='tags__list'>
                  <li className='tag' onClick={this.resetPriceSelector}>
                    <span className='tag__title'>
                      <span>{localizedNumberFormatter(selectedPrice.min)} - {localizedNumberFormatter(selectedPrice.max)} </span>
                      <span>{selectedPriceCurrency.title}</span>
                    </span>
                    <span className='tag__hover-hint'>
                      { I18n.t('apps.lb_showroom.postal_districts.remove') }
                    </span>
                    <SvgIco name="close" size={18} />
                  </li>
                </ul>
              )}
            </div>)}

          <div className='search-agent__overview-section'>
            <h3>{I18n.t('search_agent.size')}</h3>

            {(sizeIsPristine && employeesIsPristine) && (
              <p>{I18n.t('search_agent.overview.empty_size')}</p>
            )}

            {(!sizeIsPristine || !employeesIsPristine) && (
              <ul className='tags__list'>
                {!sizeIsPristine && showAreaRangeAsM2 &&
                  <li className='tag' onClick={this.resetAreaSizeSelector}>
                    <span className='tag__title'>
                      <span>{localizedNumberFormatter(selectedAreaSize.min)} - {localizedNumberFormatter(selectedAreaSize.max)} </span>
                      <span>{I18n.t('search_agent.configuration.size.selector_currency')}</span>
                    </span>
                    <span className='tag__hover-hint'>{I18n.t('apps.lb_showroom.postal_districts.remove')}</span>
                    <SvgIco name="close" size={18} />
                  </li>
                }
                {!employeesIsPristine && !showAreaRangeAsM2 &&
                  <li className='tag' onClick={this.resetAreaSizeSelector}>
                    <span className='tag__title'>{selectedEmployees.min} - {selectedEmployees.max} {I18n.t('search_agent.configuration.employees.selector_currency')}</span>
                    <span className='tag__hover-hint'>{I18n.t('apps.lb_showroom.postal_districts.remove')}</span>
                    <SvgIco name="close" size={18} />
                  </li>
                }
              </ul>
            )}

          </div>

          <div className='search-agent__overview-section'>
            <h3>{I18n.t('search_agent.contact_information')}</h3>

            {(!user.name && !user.company && !user.email && !user.phone) && (
              <p>{I18n.t('search_agent.overview.empty_contact_information')}</p>
            )}

            <div className='search-agent__overview-user'>
              {user.name && (<div className='search-agent__overview-user-item'>
                <div className='search-agent__overview-user-field'>{I18n.t('search_agent.name')}</div>
                <div className='search-agent__overview-user-info'>{user.name}</div>
              </div>)}
              {user.email && (<div className='search-agent__overview-user-item'>
                <div className='search-agent__overview-user-field'>{I18n.t('search_agent.email')}</div>
                <div className='search-agent__overview-user-info'>{user.email}</div>
              </div>)}
              {user.phone && (<div className='search-agent__overview-user-item'>
                <div className='search-agent__overview-user-field'>{I18n.t('search_agent.phone')}</div>
                <div className='search-agent__overview-user-info'>{user.phone}</div>
              </div>)}
              {user.company && (<div className='search-agent__overview-user-item'>
                <div className='search-agent__overview-user-field'>{I18n.t('search_agent.company')}</div>
                <div className='search-agent__overview-user-info'>{user.company}</div>
              </div>)}

            </div>

            { this.showOverviewButtonOnMobile() && (
              <div className='search-agent__overview-submit mobile'>
                <button className={`b-button ${this.checkAllErrors() ? 'search-agent-sign-up' : ''}`} onClick={e => this.createSearchAgent()} disabled={this.state.isButtonDisabled}>
                  <SvgIco name="search-agent" />
                  {!isEditMode && <span>{I18n.t('search_agent.create_search_agent')}</span>}
                  {isEditMode && <span>{I18n.t('search_agent.update_search_agent')}</span>}
                </button>
                {isEditMode && (
                  <div
                    onClick={this.toggleModal}
                    className='search-agent__unsubscribe-action'>
                    { I18n.t('search_agent.unsubscribe_search_agent') }
                  </div>
                )}
              </div>
            )}

          </div>

        </div>
      </div>);


    return (
      <div className={`search-agent__config-container ${isEditMode ? 'search-agent--edit-mode' : ''}`}>
        <DataProtectionModal
          isShowingModal={this.state.isShowingModal}
          handleClose={this.closeInfoModal}
          title={I18n.t('apps.lb_showroom.detailed.order_form.data_protection-search-agent')}
          content={I18n.t('apps.lb_showroom.detailed.order_form.modal.data_protection_body-search-agent')}
        />
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-7 col-lg-6 col-xl-5'>
              {!isEditMode && <p>{I18n.t('search_agent.configurations.description')}</p>}

              <div ref={ re => {this.areaRef = re} }>
                <AreaSelector
                  dynamicSearchAreas={dynamicSearchAreas}
                  selectSearchArea={this.selectSearchArea}
                  searchAction={this.areaSearchAction}
                  showError={areaError}
                  handleBreadcrumbClick={this.handleBreadcrumbClick}
                  dynamicAreas={dynamicAreas}
                  selectedAreas={selectedAreas}
                  selectArea={this.selectArea}
                  path={path}
                  currentArea={currentArea}
                  backAction={this.backAction}
                  selectAll={this.selectAllAreas}
                  deselectAll={this.deselectAllAreas}
                  required={true}
                />
              </div>

              <div ref={ re => {this.typeRef = re} }>
                <TypeSelector
                  dynamicPropertyTypes={reformatedTypes}
                  selectedPropertyTypes={selectedPropertyTypes}
                  selectPropertyType={this.selectPropertyType}
                  required={true}
                  showError={typeError}
                />
              </div>

              {isSaleAgent && (
                <div ref={ re => {this.sectionRef = re} }>
                  <SectionSelector
                    dynamicSections={reformatedSections}
                    selectedSections={selectedSections}
                    selectSection={this.selectSection}
                    required={true}
                    showError={sectionError}
                  />
                </div>)}

              { isDK && (
                <div className='search-agent__config-block'>
                  <h2 className='search-agent__header-block'>
                    { I18n.t('search_agent.configuration.price.title') }
                    <SvgIco name="info_outline" size={18} />
                    <Tooltip tooltipContent={I18n.t('search_agent.configuration.range_info')} />
                  </h2>
                  <p>{ I18n.t('search_agent.configuration.price.description') }</p>

                  <h3>{ I18n.t('search_agent.configuration.price_currency.title') }:</h3>

                  {!isSaleAgent && this.state.priceCurrencies.map(priceCurrency => {
                    if(priceCurrency.hidden) {
                      return null;
                    } else {
                      return (<RadioInput
                        onChange={this.changePriceCurrency}
                        key={priceCurrency.id}
                        value={priceCurrency}
                        checkedValue={this.state.selectedPriceCurrency}
                        title={capitalize(priceCurrency.title)} />)
                    }
                  })}

                  <RangeSelector
                    allowBiggerValue={true}
                    minValue={this.state.minPrice}
                    maxValue={this.state.maxPrice}
                    value={this.state.selectedPrice}
                    handleSelectorChange={this.handlePriceSelectorChange}
                    dimension={'KR.'}
                    showMaxMoreIcon
                  />

                </div>)}

              <div className='search-agent__config-block'>
                <h2 className='search-agent__header-block'>
                  { I18n.t('search_agent.size') }
                  <SvgIco name="info_outline" size={18} />
                  <Tooltip tooltipContent={I18n.t('search_agent.configuration.range_info')} />
                </h2>

                <p>{I18n.t('search_agent.configuration.size.description')}</p>

                <div className='radio__wrapper range' onClick={() => this.setState({ showAreaRangeAsM2: true })}>
                  <div className={`radio__checkbox-wrapper ${showAreaRangeAsM2 ? ' active': ''}`}>
                    <div className={`radio ${showAreaRangeAsM2 ? ' radio__active': ''}`} />
                  </div>
                  <input
                    type='radio'
                    name={'selectedAreaSize'}
                    value={true}
                    checked={showAreaRangeAsM2}
                    onChange={e => {}}
                    hidden />
                  <label htmlFor={'selectedAreaSize'}>{ capitalize(I18n.t('search_agent.configuration.size.selector_title')) }</label>
                </div>

                <div className='radio__wrapper range' onClick={() => this.setState({ showAreaRangeAsM2: false })}>
                  <div className={`radio__checkbox-wrapper ${!showAreaRangeAsM2 ? ' active': ''}`}>
                    <div className={`radio ${!showAreaRangeAsM2 ? ' radio__active': ''}`} />
                  </div>
                  <input
                    type='radio'
                    name={'selectedEmployees'}
                    value={false}
                    checked={!showAreaRangeAsM2}
                    onChange={e => {}}
                    hidden />
                  <label htmlFor={'selectedEmployees'}>{ I18n.t('search_agent.configuration.employees.selector_title') }</label>
                </div>

                { showAreaRangeAsM2
                  ? <RangeSelector
                    allowBiggerValue={true}
                    minValue={this.state.minAreaSize}
                    maxValue={this.state.maxAreaSize}
                    value={this.state.selectedAreaSize}
                    handleSelectorChange={this.handleAreaSizeSelectorChange}
                    showMaxMoreIcon
                    dimension={I18n.t('search_agent.configuration.size.selector_currency')} />
                  : <RangeSelector
                    allowBiggerValue={true}
                    minValue={this.state.minEmployees}
                    maxValue={this.state.maxEmployees}
                    value={this.state.selectedEmployees}
                    handleSelectorChange={this.handleEmployeesSelectorChange}
                    showMaxMoreIcon
                    dimension={I18n.t('search_agent.configuration.employees.selector_currency')} />
                }
              </div>

              { !isEditMode && (
                <ContactForm ref={ re => {this.contactsRef = re} }
                             user={user}
                             updateUser={this.updateUser}
                             updateErrors={this.updateErrors}
                             required={true}
                             showError={contactsError}
                />
              )}


              { (user.name || userFormHasBeenUpdated) && isEditMode && (
                <ContactForm ref={ re => {this.contactsRef = re} }
                             user={user}
                             updateUser={this.updateUser}
                             updateErrors={this.updateErrors}
                             required={true}
                             showError={contactsError}
                             handleFormUpdate={this.handleFormUpdate}
                />
              )}

              <div className='search-agent__overview-submit'>
                <button
                  className={`b-button ${isEditMode ? 'search-edit-button' : ''}${isDK === 'dk' ? 'dv-search-agent__button_dk' : 'dv-search-agent__button_other_sites'} ${this.checkAllErrors() ? 'search-agent-sign-up' : ''}`}
                  onClick={e => this.createSearchAgent()} disabled={this.state.isButtonDisabled}>
                  <SvgIco name="search-agent" />
                  { !isEditMode && <span>{ I18n.t('search_agent.create_search_agent') }</span> }
                  { isEditMode && <span>{ I18n.t('search_agent.update_search_agent') }</span> }
                </button>
                { isEditMode && (
                  <div
                    onClick={this.toggleModal}
                    className='search-agent__unsubscribe-action'>

                    { I18n.t('search_agent.unsubscribe_search_agent') }
                  </div>
                )}
              </div>

              <div className="data-protection-link">
                <SvgIco name="lock" size={19} />
                <span className="info-label" onClick={this.openInfoModal}>
                  { I18n.t('apps.lb_showroom.detailed.order_form.data_protection') }
                </span>
              </div>
            </div>

            <div className="col-12 col-md-5 offset-lg-1 offset-xl-2 search-agent__overview-wrapper" style={{ 'zIndex': overviewIsOpened ? 99999 : 998 }}>
              <div className='search-agent__overview-mobile'>{ overviewBlock }</div>
              <Sticky top="#top-menu" bottomBoundary='.search-agent__config-container' onStateChange={this.handleStateChange}>{ overviewBlock }</Sticky>
            </div>

            { isModalOpen &&
              <UnsubscribeModal
                unsubscribePath={this.props.unsubscribePath}
                destroyShowPath={this.props.destroyShowPath}
                isOpen={isModalOpen}
                handleRedirect={this.props.handleRedirect}
                toggleModal={this.toggleModal}
                token={this.props.token}
                searchAgentParams={this.props.searchAgentParams}
                showNotification={this.props.showNotification}
                locale={this.props.locale}
                searchLeasesLink={this.props.searchLeasesLink}
              />
            }
          </div>
        </div>
      </div>
    )
  }
}

export default SearchAgentConfigurations;
