import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

import { arrayIncludesObject } from '../helpers/array';
import { isMobile } from '../../utils/helpers';
import { SvgIco } from './Icons';

import './DropdownSelector.sass';


class DropdownComplexSelector extends Component {
  state = {
    isOpened: this.props.isOpened || false,
    columns: this.props.columns || 1,
  };

  componentDidMount() {
    isMobile()
     ? document.addEventListener('touchstart', this.handleClickOutside)
     : document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    isMobile()
      ? document.addEventListener('touchstart', this.handleClickOutside)
      : document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {

    if(this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        ...this.state,
        isOpened: false
      })
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  itemIsSelected = (id) => {
    return arrayIncludesObject(this.props.selectedData, 'id', id);
  };

  handleClick = () => {
    this.setState({
      ...this.state,
      isOpened: !this.state.isOpened
    });
  };

  entireDataIsSelected = dataObject => {
    return Object.keys(dataObject).every(key => {
      return this.itemIsSelected(dataObject[key].id);
    })
  };


  render() {

    const {
      isOpened,
    } = this.state;

    const {
      backAction,
      selectAll,
      deselectAll,
      dynamicData,
      placeholder,
      path,
      currentArea,
      selectedData,
    } = this.props;

    const dynamicPlaceholder = `${placeholder} ${selectedData.length > 0 ? `(${selectedData.length})` : ''}`;
    const columnClasses = classNames('dropdown-selector__column', { 'first-page': path.length <= 1 });
    const bodyClasses = classNames('dropdown-selector__body', { 'first-page': path.length <= 1 });


    return (
      <div className='dropdown-selector' ref={this.setWrapperRef}>
        <div className='dropdown-selector__trigger' onClick={this.handleClick}>
          <div className='dropdown-selector__placeholder'>{ dynamicPlaceholder }</div>
          <div className='dropdown-selector__action-icon dropdown-selector__action-icon--colored'>
            { isOpened
              ? <SvgIco name="expand-less" size={24} />
              : <SvgIco name="expand-more" size={24} />
            }
          </div>
        </div>

        { isOpened && (
          <div className={bodyClasses}>
            { path.length > 1 &&
              <div className="dropdown-selector__top-block">
                <div className="dropdown-selector__breadcrumbs-wrapper">
                  <div className="dropdown-selector__back-btn" onClick={backAction}>
                    <SvgIco name="arrow-back" size={18} />
                    <span>{ I18n.t('generic.back') }</span>
                  </div>
                  <div className="dropdown-selector__breadcrumbs">
                    { path.map((item, index) =>
                      <div className="dropdown-selector__breadcrumbs-link" key={index}>
                          <span data-name={item.name}
                                data-index={index}
                                onClick={ () => this.props.handleBreadcrumbClick(index) }
                          >
                            { item.name }
                          </span>
                        <SvgIco name="keyboard-arrow-right-rounded" size={16} />
                      </div>) }
                  </div>
                </div>
                { currentArea && currentArea.postal_districts &&
                  <div className="dropdown-selector__select-buttons">
                    <div className='dropdown-selector__button select-all' onClick={selectAll}>{ I18n.t('search_agent.select_all') }</div>
                    <div className='dropdown-selector__button deselect-all' onClick={deselectAll}>{ I18n.t('search_agent.deselect_all') }</div>
                  </div>
                }
              </div>
            }
            { Object.keys(dynamicData).length > 0 &&
              <div className={columnClasses}>
                { Object.keys(dynamicData).map(key => {
                  return (
                    <div key={ dynamicData[key].id || dynamicData[key].name }
                         onClick={e => this.props.selectAction(e, dynamicData[key])}
                         className={`dropdown-selector__item ${this.itemIsSelected(dynamicData[key].id) ? 'dropdown-selector__item--selected' : ''}`}>

                      {(!dynamicData[key].countries && !dynamicData[key].provinces && !dynamicData[key].postal_districts && this.itemIsSelected(dynamicData[key].id)) && (
                        <Fragment>
                          <div className='dropdown-selector__item-icon'>
                            <SvgIco className="complex-selector-check" name="check" size={16} />
                          </div>
                          <div className='dropdown-selector__item-name checkbox-label'>{dynamicData[key].name}</div>
                        </Fragment>
                      )}

                      {(!dynamicData[key].countries && !dynamicData[key].provinces && !dynamicData[key].postal_districts && !this.itemIsSelected(dynamicData[key].id)) && (
                        <Fragment>
                          <div className='dropdown-selector__item-icon'>
                            <div className='dropdown-selector__item-checkbox' />
                          </div>
                          <div className='dropdown-selector__item-name checkbox-label'>{dynamicData[key].name}</div>
                        </Fragment>
                      )}

                      { (dynamicData[key].countries || dynamicData[key].provinces || dynamicData[key].postal_districts) &&
                        <Fragment>
                          <div className='dropdown-selector__item-name'>{dynamicData[key].name}</div>
                          <SvgIco className="arrow" name="keyboard-arrow-right-rounded" size={24} />
                        </Fragment>
                      }
                    </div>
                  )
                })}
              </div>
            }
          </div>
        )}
      </div>
    );
  }
}

export default DropdownComplexSelector;
