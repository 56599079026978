import React from 'react';

import './Tooltip.sass';


const Tooltip = ({ tooltipContent, tooltipSuitableForOnly }) => {
  return (
    <div className="my-tooltip">
      { tooltipContent } {tooltipSuitableForOnly}
    </div>
  )
};

export default React.memo(Tooltip);
