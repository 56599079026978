import React, { Component } from 'react';

import Link from '../../SharedComponents/Link';
import DeleteSearchAgentModal from './DeleteSearchAgentModal';

import './SearchAgentListItem.sass';


class SearchAgentListItem extends Component {

  state = {
    showDeleteModal: false,
  };

  componentWillUnmount() {
    this.state.showDeleteModal && this.handleCloseDeleteModal();
  }

  handleOpenDeleteModal = (e) => {
    e.stopPropagation();
    this.setState({ showDeleteModal: true });
  };

  handleCloseDeleteModal = () => this.setState({ showDeleteModal: false });

  handleDelete = () => {
    this.props.handleDelete(this.props.data.access_token, this.props.agentIndex);
    this.handleCloseDeleteModal();
    this.props.toggleNotification('delete');
  };


  render() {
    const { agentIndex, data } = this.props;
    const { showDeleteModal } = this.state;
    const { postal_districts_with_names,
            kinds_with_names,
            size,
            price,
          } = data;

    const editSearchAgentLink = `${window.location.origin}${window.location.pathname}/${data.access_token}/edit`;
    const categoryFormatter = data.sections.map(section => I18n.t(`generic.models.property.category.${section}`, {count: 1})).join(', ');
    const category = categoryFormatter.charAt(0).toUpperCase() + categoryFormatter.substring(1);
    const areas = postal_districts_with_names.map(district => district.name).join(', ');
    const types = kinds_with_names.map(type => type.name).join(', ');


    return (
      <div className="sa-list-item">

        <div className="sa-list-item__top-section">
          <h2>{ I18n.t('generic.search_agent') } #{ agentIndex + 1 }</h2>
          <div className="sa-list-item__manage-buttons">
            <Link href={editSearchAgentLink} className="sa-list-item__edit-button">
              <div className="sa-list-item__edit-icon" />
              <span>{ I18n.t('generic.edit') }</span>
            </Link>
            <div className="sa-list-item__delete-button" onClick={this.handleOpenDeleteModal}>
              <div className="sa-list-item__delete-icon" />
              <span>{ I18n.t('generic.delete') }</span>
            </div>
          </div>
        </div>

        <div className="sa-list-item__content">
          <p className="sa-list-item__subtitle">
            { I18n.t('search_agents.edit.list_item_subheader') }
          </p>

          <div className="sa-list-item__configurations row">
            <div className="sa-list-item__configuration-wrapper col-12 col-md-4 col-lg-3 col-xl-3">
              <div className="sa-list-item__configuration">
                <h3>{ I18n.t('activerecord.attributes.property.category') }</h3>
                <p>{ category }</p>
              </div>
              <div className="sa-list-item__configuration">
                <h3>{ I18n.t('search_agent.area') }</h3>
                <p>{ areas }</p>
              </div>
            </div>

            <div className="sa-list-item__configuration-wrapper col-12 col-md-4 col-lg-3 col-xl-3 offset-lg-1 offset-xl-1">
              <div className="sa-list-item__configuration">
                <h3>{ I18n.t('search_agent.type') }</h3>
                <p>{ types }</p>
              </div>
              <div className="sa-list-item__configuration">
                <h3>{ I18n.t('search_agent.price') }</h3>
                <p>{ price }</p>
              </div>
            </div>

            <div className="sa-list-item__configuration-wrapper col-12 col-md-4 col-lg-3 col-xl-3 offset-lg-1 offset-xl-1">
              <div className="sa-list-item__configuration">
                <h3>{ I18n.t('search_agent.size') }</h3>
                <p>{ size }</p>
              </div>
            </div>
          </div>
        </div>

        { showDeleteModal &&
          <DeleteSearchAgentModal
            handleDelete={this.handleDelete}
            handleClose={this.handleCloseDeleteModal}
          />
        }
      </div>
    )
  }
}

export default SearchAgentListItem;
