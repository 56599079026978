import React, { Component, Fragment } from 'react';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';

import { apiProvider } from '../../../api/core';
import AdditionalOption from '../../SharedComponents/AdditionalOption';
import Notification from '../../SharedComponents/Notification';
import { SvgIco } from "../../SharedComponents/Icons";
import SearchAgentListItem from './SearchAgentListItem';

import './index.sass';


class ManageSearchAgents extends Component {
  state = {
    currentPage: 0,
    searchAgentsListStartIndex: 0,
    itemsToShow: 4,
    showNotification: false,
    notificationMessage: '',
    activeSearchAgents: [],
    isLoading: true,
  };

  timer;

  componentDidMount() {
    const { notificationAction, editedToken } = this.props;
    const token = queryString.parse(window.location.search).token;
    const admin_context = queryString.parse(window.location.search).admin_context || false;
    const url = '/lease/api/frontend/search_agents?token=' + token + '&admin_context=' + admin_context;

    apiProvider.getAll(url)
               .then(response => {
                 this.setState({
                   activeSearchAgents: response || [],
                   isLoading: false
                 }, () => {
                   notificationAction && this.handleRetrievedNotification(notificationAction, editedToken);
                   window.scrollTo(0, 0);
                   this.handlePaginationChange();
                 });
               })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { notificationAction, editedToken, page } = this.props;

    notificationAction &&
    this.state.activeSearchAgents &&
    notificationAction !== prevProps.notificationAction &&
    this.handleRetrievedNotification(notificationAction, editedToken);

    page !== prevProps.page && this.handlePaginationChange();
  }

  handlePaginationChange = () => {
    const { page, handleRedirect } = this.props;
    const { itemsToShow, activeSearchAgents } = this.state;
    const searchAgentsLength = activeSearchAgents && activeSearchAgents.length;
    const pageCount = Math.ceil(searchAgentsLength / itemsToShow);
    const pageParam = queryString.parse(window.location.search).page;

    if (page > pageCount || (pageParam && !page) || (page === 1 && parseInt(pageParam) === 1)) {
      const currentUrl = window.location.href;
      const redirectUrl = page > pageCount
        ? currentUrl.replace(`&page=${pageParam}`, `&page=${pageCount}`)
        : (pageParam && !page) || (page === 1 && parseInt(pageParam) === 1)
        ? currentUrl.replace(`&page=${pageParam}`, '')
        : currentUrl;

      handleRedirect(redirectUrl);

    } else {
      const currentPage = page ? (page <= pageCount ? page - 1 : pageCount - 1) : 0;

      this.setState({ currentPage, searchAgentsListStartIndex: currentPage * this.state.itemsToShow });
      setTimeout(() => window.scrollTo(0, 0), 10);
    }
  };

  handleRetrievedNotification = (notificationAction, editedToken) => {
    this.toggleNotification(notificationAction, editedToken);
    this.props.showNotification(false);
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    let currentPage = queryString.parse(window.location.search).page ? parseInt(queryString.parse(window.location.search).page) : 0;

    if (selectedPage !== currentPage) {
      const currentUrl = window.location.href;
      const newUrl = selectedPage === 1
        ? currentUrl.replace(`&page=${currentPage}`, '')
        : currentPage === 1 || currentPage === 0
        ? currentUrl.concat(`&page=${selectedPage}`)
        : currentUrl.replace(`&page=${currentPage}`, `&page=${selectedPage}`);

      this.props.handleRedirect(newUrl);
    }
  };

  handleDelete = (agentToken, agentIndex) => {
    const newAgentsList = [...this.state.activeSearchAgents];
    const unsubscribePath = `/lease/api/frontend/search_agents/${agentToken}`;

    apiProvider.remove(unsubscribePath)
               .then(response => {
                 if (response) {
                   newAgentsList.splice(agentIndex, 1);
                   this.setState({ activeSearchAgents: newAgentsList }, () => {
                     this.toggleNotification('delete');
                     this.handlePaginationChange();
                   })
                 }
               });
  };

  toggleNotification = (action, token) => {
    const editedAgentNumber = token ? `${this.state.activeSearchAgents.findIndex(agent => agent.access_token === token) + 1} ` : ' ';
    const notificationMessage = action === 'create'
      ? `${I18n.t('search_agents.create.header')}.`
      : action === 'delete'
      ? `${I18n.t('search_agents.destroy.search_agent_deleted')}.`
      : I18n.t('search_agents.edit.notification', { agent_number: editedAgentNumber });

    this.state.showNotification && clearTimeout(this.timer);
    this.setState({ notificationMessage: notificationMessage, showNotification: true });
    this.timer = setTimeout(() => this.setState({ showNotification: false }), 5000);
  };

  closeNotification = () => this.setState({ showNotification: false }, () => clearTimeout(this.timer));

  handleLinkClick = (event, href) => {
    if (!event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      this.props.handleRedirect(href);
    }
  };


  render() {
    const { searchLeasesLink, newSearchAgentLink, siteKey } = this.props;
    const { searchAgentsListStartIndex, itemsToShow, showNotification, notificationMessage, activeSearchAgents, isLoading, currentPage } = this.state;
    const searchAgentsLength = activeSearchAgents && activeSearchAgents.length;
    const agentsListStartNumber = searchAgentsListStartIndex + 1;
    const agentsListEndNumber = agentsListStartNumber === searchAgentsLength
                                ? 0
                                : agentsListStartNumber + (itemsToShow - 1) > searchAgentsLength
                                ? searchAgentsLength
                                : agentsListStartNumber + (itemsToShow - 1);

    const searchAgentsShowNumbers = `№ ${agentsListStartNumber}${agentsListEndNumber ? `-${agentsListEndNumber}` : ''}`;
    const optionLinkGridClasses = 'col-12 col-md-12 col-lg-6 col-xl-6';
    const optionInlineClasses = 'inline col-12 col-md-12 col-lg-12 col-xl-12';
    const spinner =
      <div className="spinner-wrapper">
        <div className="smoothie-spinner" />
      </div>;


    return (
      <div className="manage-sa">
        <Notification message={notificationMessage} visible={showNotification} handleClose={this.closeNotification}/>

        <div className="manage-sa__top-section container">
          <div className="manage-sa__headline">
            <h1>{ I18n.t('search_agent.manage_list.headline') }</h1>
            <p>{ I18n.t('search_agent.manage_list.preline', { agents_count: searchAgentsLength ? ` ${ searchAgentsLength } ` : ' ' })}</p>
          </div>
          <img src={require('../../../src/images/search-agent-top-image.png')} alt="Search Agent" className="lazyload" />
        </div>

        <div className="manage-sa__agents-list">
          <div className="container">
            { isLoading
              ? spinner
              : <Fragment>
                { searchAgentsLength > 0 ?
                  <Fragment>
                    <p className="manage-sa__agents-list-title">
                      { I18n.t('search_agent.manage_list.show', { agents_count: searchAgentsLength, show_count: searchAgentsShowNumbers })}
                    </p>

                    { activeSearchAgents.map((agent, index) =>
                      index >= searchAgentsListStartIndex && index < searchAgentsListStartIndex + itemsToShow &&
                      <SearchAgentListItem
                        data={agent}
                        agentIndex={index}
                        key={index}
                        handleDelete={this.handleDelete}
                        toggleNotification={this.toggleNotification}
                      />
                    )}

                    { searchAgentsLength > 4 &&
                      <div className="manage-sa__pagination-wrapper">
                        <ReactPaginate
                          previousLabel={<SvgIco className="left" name="keyboard-arrow-right-rounded-ios" size={32} />}
                          nextLabel={<SvgIco className="right" name="keyboard-arrow-right-rounded-ios" size={32} />}
                          pageCount={Math.ceil(searchAgentsLength / itemsToShow)}
                          breakLabel={'...'}
                          pageRangeDisplayed={2}
                          marginPagesDisplayed={1}
                          onPageChange={this.handlePageClick}
                          containerClassName={'pagination'}
                          activeClassName={'active'}
                          forcePage={currentPage}
                        />
                    </div>
                    }
                  </Fragment>
                 :
                  <div className="manage-sa__empty-results row">
                    <div className="manage-sa__empty-results-wrapper col-12 col-md-10 col-lg-10 col-xl-8 offset-md-1 offset-lg-1 offset-xl-2">
                      <h2>{ I18n.t('search_agent.manage_list.empty_headlline') }</h2>
                      <p>{ I18n.t('search_agent.manage_list.empty_subheader') }</p>
                      <a href={newSearchAgentLink} className="manage-sa__create-sa-button" onClick={(e) => this.handleLinkClick(e, newSearchAgentLink)}>
                        <span>{ I18n.t('search_agent.create_search_agent') }</span>
                        <SvgIco name="keyboard-arrow-right-rounded-ios" size={20} />
                      </a>
                    </div>
                  </div>
                }
              </Fragment>
            }
          </div>
        </div>

        <div className="manage-sa__options-links-wrapper container">
          <div className="row">
            <AdditionalOption
              headline={I18n.t('search_agents.index.browse_header')}
              content={I18n.t('search_agents.index.browse_body')}
              linkContent={I18n.t('search_agents.index.browse_cta')}
              buttonColor="orange"
              link={searchLeasesLink}
              gridClasses={searchAgentsLength ? `mobile-padding ${optionLinkGridClasses}` : optionInlineClasses}
            />

            { searchAgentsLength > 0 &&
              <AdditionalOption
                headline={I18n.t('search_agents.index.search_agent_header')}
                content={I18n.t('search_agents.index.search_agent_body')}
                linkContent={I18n.t('search_agents.index.search_agent_cta')}
                buttonColor="green"
                link={newSearchAgentLink}
                gridClasses={optionLinkGridClasses}
                handleClick={this.handleLinkClick}
                siteKey={siteKey}
              />
            }

          </div>
        </div>
      </div>
    )
  }
}

export default ManageSearchAgents;
