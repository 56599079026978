import React, { Component } from 'react';

import { apiProvider } from '../../../api/core';
import Loader from '../../SharedComponents/Loader';
import { SvgIco } from '../../SharedComponents/Icons';

import './UnsubscribeModal.sass';


class UnsubscribeModal extends Component {

  state = {
    isLoading: false,
  };

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    e.stopPropagation();
    !this.modalBody.contains(e.target) && this.props.toggleModal(e);
  };

  removeSearchAgent = () => {
    const { searchAgentParams, token, showNotification, handleRedirect, locale, searchLeasesLink } = this.props;
    const unsubscribePath = `/lease/api/frontend/search_agents/${searchAgentParams.access_token}`;
    const urlIncludesLocale = searchLeasesLink.length > 1;
    const href = `${urlIncludesLocale ? searchLeasesLink : ''}/${I18n.t('routes.search_agents')}?site=${locale}&token=${token}`;

    this.setState({ isLoading: true });

    apiProvider.remove(unsubscribePath)
               .then(response =>
                 response && this.setState({ isLoading: false }, () => {
                   showNotification(true, 'delete');
                   handleRedirect(href);
                 }))
  };

  render() {

    const { isLoading } = this.state;
    const { toggleModal } = this.props;

    return (
      <div className="delete-modal">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8 col-xl-8">
              <div className="delete-modal__body" ref={el => this.modalBody = el}>
                <p dangerouslySetInnerHTML={{__html: I18n.t('search_agent.modal.remove_hint')}} />
                <p>{ I18n.t('search_agent.modal.remove_question') }</p>
                <div className="delete-modal__buttons">
                  <button className="delete-modal__button delete-modal__button--cancel" onClick={toggleModal} disabled={isLoading}>
                    { I18n.t('search_agent.modal.cancel') }
                  </button>
                  <button className="delete-modal__button delete-modal__button--confirm" onClick={this.removeSearchAgent} disabled={isLoading}>
                    <div className="button-content">
                      { isLoading && <Loader size={18} left={-24} top={3}/> }
                      { I18n.t('search_agent.modal.yes_unsubscribe') }
                    </div>
                  </button>
                </div>
                <div className="delete-modal__close-btn" onClick={toggleModal}>
                  <SvgIco name="close" size={24} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UnsubscribeModal;
