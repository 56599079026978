import React, { Component } from 'react';
import classNames from 'classnames';

import frontpageIcon from '../../src/images/frontpage.svg';
import becomeProviderIcon from '../../src/images/become-provider.svg';
import { loadTawkTo } from '../TawkTo/TawkTo';
import SearchAgentBanner from '../SharedComponents/SearchAgentBanner';
import { SvgIco } from "../SharedComponents/Icons";

import './index.sass';


class ErrorPage extends Component  {

  componentDidMount() {
    loadTawkTo(this.props.siteKey);
  }


  render() {

    const { siteName, paths, errorType, siteKey } = this.props;
    let refreshThePage;
    let mainTitle;
    let pageTitle;
    let pageText;

    refreshThePage = () => window.location.reload();

    switch(errorType) {
      case 'server_error':
        mainTitle = <h1>500 {I18n.t('error_page.general.error')}</h1>;
        pageTitle = I18n.t('error_page.general.server_error_subtitle');
        pageText = I18n.t('error_page.general.server_error_description');
        break;
      default:
        mainTitle = <h1>404 {I18n.t('error_page.general.error')}</h1>;
        pageTitle = I18n.t('error_page.not_found.title');
        pageText = I18n.t('error_page.not_found.text');
    }

    const isServerError = errorType === 'server_error';
    const subheadingClass = classNames('error-page__subheading', { 'error-page__subheading-500': isServerError });
    const headerTextClass = classNames('error-page__header-text col-lg-8 offset-lg-2 col-xl-6 offset-xl-3',
      { 'error-page__header-text--500 col-md-10 offset-md-1': isServerError,
        'col-md-8 offset-md-2': !isServerError,
      });


    return (
      <div className='error-page'>
        <div className='container'>
          <div className='error-page__header-container'>
            { mainTitle }
            <h2 className={subheadingClass}>{ pageTitle }</h2>
            <p className={headerTextClass}>{ pageText }</p>
            { errorType === 'server_error' && (
              <p className="error-page__server-error-links">
                <a className="return-front-page" href="/">
                  { I18n.t("error_page.general.return_to_frontpage") }
                </a>
                &nbsp;{ I18n.t('error_page.general.part_text_between_links') }&nbsp;
                <span onClick={refreshThePage}>{I18n.t('error_page.general.refresh_this_page')}.</span>
              </p>
            )}
          </div>
        </div>

        { errorType !== 'server_error' && (
          <div className='container'>
            <div className="error-page__main-container">
              <h2>{ I18n.t('error_pages.move_on') }</h2>
            </div>
            <div className='error-page__actions row'>
              <div className='error-page__action col-12 col-md-6 col-lg-4 offset-lg-2 col-xl-4 offset-xl-2'>
                <div className='error-page__action-body'>
                  <div className='error-page__action-icon lazyload' data-bg={frontpageIcon} data-sizes="auto" />
                  <h3 className='tac'>{ I18n.t('error_page.general.go_to_front') }</h3>
                  <p className='tac'>{ I18n.t('error_page.general.go_to_front_text') }</p>
                </div>
                <div className="error-page__button-wrapper">
                  <a href={paths.root} className='b-button'>
                    { siteName }
                    <SvgIco name="keyboard-arrow-right-rounded" size={32} />
                  </a>
                </div>
              </div>
              <div className='error-page__action col-12 col-md-6 col-lg-4 col-xl-4'>
                <div className='error-page__action-body'>
                  <div className='error-page__action-icon lazyload' data-bg={becomeProviderIcon} data-sizes="auto" />
                  <h3 className='tac'>{ I18n.t('error_page.general.list_office') }</h3>
                  <p className='tac'>{ I18n.t('error_page.general.list_office_text', { 'site': siteName }) }</p>
                </div>
                <div className="error-page__button-wrapper">
                  <a href={paths.signupTeaser} className='b-button'>
                    { I18n.t('error_page.general.list_office_button') }
                    <SvgIco name="keyboard-arrow-right-rounded" size={32} />
                  </a>
                </div>
              </div>
            </div>
            <SearchAgentBanner
              siteKey={siteKey}
              newSearchAgentPath={paths.newSearchAgent}
              searchAgentHeadline={I18n.t('error_page.general.create_search_agent')}
              searchAgentText={I18n.t('error_page.general.create_search_agent_text')}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ErrorPage;
