import React, { Component } from 'react';

import { SvgIco } from '../../SharedComponents/Icons';

import './DeleteSearchAgentModal.sass';

class DeleteSearchAgentModal extends Component {

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    !this.modalBody.contains(e.target) && this.props.handleClose();
  };

  render() {

    const { handleDelete, handleClose } = this.props;

    return (
      <div className="delete-modal">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8 col-xl-8">
              <div className="delete-modal__body" ref={el => this.modalBody = el}>
                <p dangerouslySetInnerHTML={{__html: I18n.t('search_agent.modal.remove_hint')}} />
                <p>{ I18n.t('search_agent.modal.remove_question') }</p>
                <div className="delete-modal__buttons">
                  <button className="delete-modal__button delete-modal__button--cancel" onClick={handleClose}>
                    { I18n.t('search_agent.modal.cancel') }
                  </button>
                  <button className="delete-modal__button delete-modal__button--confirm" onClick={handleDelete}>
                    { I18n.t('search_agent.modal.yes_unsubscribe') }
                  </button>
                </div>
                <div className="delete-modal__close-btn" onClick={handleClose}>
                  <SvgIco name="close" size={24} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DeleteSearchAgentModal;
