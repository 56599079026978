import React from 'react';
import { SvgIco } from './Icons';


export const TagsList = props => (
  <ul className='tags__list'>
    {props.tags.map(tag => {
      return (
        <li className='tag' key={tag.id} onClick={e => props.removeTag(e, tag.id)}>
          <span className='tag__title'>
            { tag.name }
          </span>
          <span className='tag__hover-hint'>
            { I18n.t('apps.lb_showroom.postal_districts.remove') }
          </span>
          <SvgIco name="close" size={24} />
        </li>
      )
    })}
  </ul>
);
