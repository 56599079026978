import React, { Component } from 'react';

import DropdownPlainSelector from '../../SharedComponents/DropdownPlainSelector';
import DropdownComplexSelector from '../../SharedComponents/DropdownComplexSelector';
import { SvgIco } from "../../SharedComponents/Icons";

import './AreaSelector.sass';


class AreaSelector extends Component {

  state = {
    isOpened: this.props.isOpened || false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.target.tagName !== 'svg' && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isOpened: false,
        searchValue: '',
        loadingIsStarted: false,
      })
    }
  };

  handleClick = () => {
    this.setState({
      isOpened: !this.state.isOpened
    });
  };

  render() {

    const { required,
            showError,
            dynamicSearchAreas,
            selectedAreas,
            selectSearchArea,
            searchAction,
            dynamicAreas,
            selectArea,
            handleBreadcrumbClick,
            enableAreasSearch,
            activeAreaId,
            backAction,
            selectAll,
            deselectAll,
            path,
            currentArea,
          } = this.props;

    const { isOpened } = this.state;
    const placeholder = `${I18n.t('search_agent.configuration.area.placeholder')} ${selectedAreas && selectedAreas.length > 0 ? `(${selectedAreas.length})` : ''}`;


    return (
      <div className='search-agent__config-block search-agent__config-area'>
        <h2 className={`search-agent__header-block ${required ? 'search-agent__required-block' : ''}`}>
          { I18n.t('search_agent.area') }
        </h2>

        { showError && <div className='search-agent__error'>{I18n.t('search_agent.area_error')}</div> }

        <p>{ I18n.t('search_agent.configuration.area.description') }</p>

        <div className="search-agent__area-search" ref={ el => this.wrapperRef = el }>
          <div className="search-agent__dropdown-trigger" onClick={this.handleClick}>
            <span>{ placeholder }</span>
            { isOpened
              ? <SvgIco name="expand-less" size={24} />
              : <SvgIco name="expand-more" size={24} />
            }
          </div>
          { isOpened &&
            <DropdownPlainSelector
              dynamicData={dynamicSearchAreas}
              selectedData={selectedAreas}
              selectAction={selectSearchArea}
              searchAction={searchAction}
              enableSearch={true}
              placeholder={I18n.t('generic.search')}
              selectedCounterPhrase='fee' />
          }
        </div>

        <div className="search-agent__area-separator">
          { I18n.t('generic.or') }
        </div>

        <div className="search-agent__area-navigator">
          <DropdownComplexSelector
            dynamicData={dynamicAreas}
            selectedData={selectedAreas}
            selectAction={selectArea}
            handleBreadcrumbClick={handleBreadcrumbClick}
            enableSearch={enableAreasSearch}
            columns={2}
            activeId={activeAreaId}
            backAction={backAction}
            selectAll={selectAll}
            deselectAll={deselectAll}
            path={path}
            currentArea={currentArea}
            placeholder={I18n.t('search_agent.configuration.area.placeholder2')} />

        </div>

      </div>
    )
  }
}

export default AreaSelector;
