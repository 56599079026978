import React from 'react';
import Modal from 'react-modal';
import { SvgIco } from '../SharedComponents/Icons';

import './DataProtectionModal.sass';


const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    top: '52px',
    overflow: 'auto',
    zIndex: '999'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    pointerEvents: 'visible',
    background: 'white',
    borderRadius: '4px',
    position: 'absolute',
  },
};


const DataProtectionModal = ({ isShowingModal, handleClose, title, content }) => {

  const privacyPolicyPath = 'https://' + window.location.host + '/' + I18n.t('routes.privacy_url');

  return (
    <div>
      <Modal
        ariaHideApp={false}
        className="data-protection-modal"
        closeTimeoutMS={500}
        contentLabel="Error Modal"
        isOpen={isShowingModal}
        onRequestClose={handleClose}
        style={customStyles}
      >
        <div className="modal-header">
          <h2>{ title }</h2>
          <div className="modal-close-btn" onClick={handleClose}>
            <SvgIco name="close" size={22} />
          </div>
        </div>
        <div className="modal-body">
          <div className="modal-body__content" dangerouslySetInnerHTML={{ __html: content }}/>
          <div className="modal-body__policy-link">
            <p>{ I18n.t('apps.lb_showroom.detailed.order_form.modal.policy_link_preline') }
              &nbsp;
              <a href={privacyPolicyPath}>
                { I18n.t('apps.lb_showroom.detailed.order_form.modal.privacy_policy') }
              </a>.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DataProtectionModal;
