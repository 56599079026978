import React from 'react';

export const RadioInput = props => {
  return (
    <div className='radio__wrapper' onClick={e => props.onChange(e, props.value)}>
      <div className={`radio__checkbox-wrapper ${props.checkedValue.id === props.value.id ? ' active': ''}`}>
        <div className={`radio ${props.checkedValue.id === props.value.id ? ' radio__active': ''}`} />
      </div>
      <input
        type='radio'
        name={props.value.id}
        value={props.value.id}
        checked={props.checkedValue.id === props.value.id}
        onChange={e => {}}
        hidden />
      <label htmlFor={props.value.id}>{props.title}</label>
    </div>
  )
};
