import React, { Component } from 'react';

import UnsubscribeModal from './UnsubscribeModal';
import searchAgentLogo from '../../../src/images/search-agent-top-image.png';
import { SvgIco } from '../../SharedComponents/Icons';

import './SearchAgentHeader.sass';

class SearchAgentAd extends Component {
  state = {
    isModalOpen: false,
    isEditMode: this.props.isEditMode,
  };

  toggleModal = (e) => {
    e.stopPropagation();
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  render() {
    const { isModalOpen, isEditMode } = this.state;

    return (
      <div className={`search-agent__ad-container ${isEditMode ? 'search-agent--edit-mode' : ''}`}>
        <div className='container'>
          <div className='row'>

            <div className='col-12 col-md-9 col-lg-8 col-xl-9'>
              {!isEditMode && <h1 className='search-agent__ad-title'>{I18n.t('search_agent.header.title')}</h1>}
              {isEditMode && <h1 className='search-agent__ad-title'>{I18n.t('search_agent.edit_search_agent')}</h1>}

              {isEditMode && (
                <div className='search-agent__ad-title-edit'>
                  <p>{I18n.t('search_agent.header.edit_text')}&nbsp;
                    <span>{I18n.t('search_agent.header.you_can')} </span>
                    <span
                      className='search-agent__unsubscribe-action'
                      onClick={this.toggleModal}
                      >
                      {I18n.t('search_agent.header.unsubscribe')}</span>
                    <span> {I18n.t('search_agent.header.search_agent_at_any_time')}</span>
                  </p>
                </div>
              )}

              {!isEditMode && (
                <ul className='search-agent__ad-list'>
                  <li>
                    <SvgIco name="check" size={24} />
                    <span>{I18n.t('search_agent.header.benefit_1')}</span>
                  </li>
                  <li>
                    <SvgIco name="check" size={24} />
                    <span>{I18n.t('search_agent.header.benefit_2')}</span>
                  </li>
                  <li>
                    <SvgIco name="check" size={24} />
                    <span>{I18n.t('search_agent.header.benefit_3')}</span>
                  </li>
                  <li>
                    <SvgIco name="check" size={24} />
                    <span>{I18n.t('search_agent.header.benefit_4')}</span>
                  </li>
                </ul>
              )}
            </div>

            <div className='col-12 col-md-3 col-lg-4 col-xl-3 search-agent__ad-logo-wrapper'>
              <div>
                <img src={searchAgentLogo} alt="logo" className="search-agent__ad-logo" />
              </div>
            </div>

          </div>
        </div>
        { isModalOpen &&
          <UnsubscribeModal
            unsubscribePath={this.props.unsubscribePath}
            handleRedirect={this.props.handleRedirect}
            destroyShowPath={this.props.destroyShowPath}
            token={this.props.token}
            searchAgentParams={this.props.searchAgentParams}
            showNotification={this.props.showNotification}
            locale={this.props.locale}
            searchLeasesLink={this.props.searchLeasesLink}
            toggleModal={this.toggleModal} />
          }

      </div>
    )
  }
}

export default SearchAgentAd;
