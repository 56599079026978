import React, { Fragment, Component } from 'react';
import "core-js/stable";
import "regenerator-runtime/runtime";
import InputRange from 'react-input-range';

import './RangeSelector.sass';


class RangeSelector extends Component {

  state = {
    minInput: 0,
    maxInput: 0,
    employeeMin: Math.round(this.props.value.min / 15),
    employeeMax: Math.round(this.props.value.max / 15),
    maxInputIsFocused: false,
    activeInput: '',
  };

  componentDidMount() {
    this.setState({
      minInput: this.props.value.min,
      maxInput: this.props.value.max,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value.min !== prevProps.value.min || this.props.value.max !== prevProps.value.max) {
      this.setState({
        minInput: this.props.value.min,
        maxInput: this.props.value.max,
        employeeMin: Math.round(this.props.value.min / 15),
        employeeMax: Math.round(this.props.value.max / 15),
      });
    }
  };

  handleChange = (e) =>
    this.setState({ [e.target.id]: e.target.value.replace(/\D/g,'') });

  handleKeyPress = (e, valueChangeFunc) => {
    if (e.key === 'Enter') {
      e.target.blur();
      this.handleValueChange(e, valueChangeFunc);
    }
  }

  handleBlur = (e, valueType, valueChangeFunc) => {
    const { value, employeeCalculation } = this.props;

    this.handleInputClick(e);

    this.setState({ maxInputIsFocused: false });
    e.target.value.replace(/\D/g,'') === ''
      ? (e.target.value = employeeCalculation ? Math.round(value[valueType] / 15) : value[valueType])
      : this.handleValueChange(e, valueChangeFunc);
  };

  handleValueChange = (e, valueChangeFunc) => {
    let currentState = Object.assign({}, this.props.value);
    const targetValue = +e.target.value.replace(/\D/g,'');
    const value = this.props.employeeCalculation ? targetValue * 15 : targetValue;
    const inputRange = e.target.dataset.range;

    if(value !== currentState[inputRange]) {
      currentState[inputRange] = valueChangeFunc(value, currentState);
      this.props.handleSelectorChange(currentState, true);
    }
  };

  minChange = (value, currentState) => {
    const { maxValue, minValue } = this.props;

    if(!value) {
      this.setState({ ...this.state, minInput: minValue });
      return minValue;
    } else {
      const maxLimit = value > currentState.max || value > maxValue;
      const minLimit = value < minValue ? minValue : value;
      return maxLimit ? currentState.max : minLimit;
    }
  };

  maxChange = (value, currentState) => {
    const { maxValue, minValue, allowBiggerValue } = this.props;
    const currentValue = +value;

    if(!currentValue) {
      this.setState({ maxInput: maxValue });
      return maxValue;
    } else {
      const maxLimit = currentValue > maxValue;
      const minLimit = currentValue < +currentState.min || currentValue < minValue ? +currentState.min : currentValue;
      this.setState({ maxInput: maxLimit ? (allowBiggerValue ? minLimit : maxValue) : minLimit });
      return maxLimit ? (allowBiggerValue ? minLimit : maxValue) : minLimit;
    }
  };

  handleSlideSelectorChange = value => {
    const { employeeCalculation, filterType } = this.props;
    const { minInput, maxInput } = this.state;
    let currentState = Object.assign({}, this.props.value);

    if(value.max > this.props.maxValue) {
      currentState.max = this.props.maxValue;
    } else if(value.min < this.props.minValue) {
      currentState.min = this.props.minValue;
    } else {
      currentState.min = value.min;
      currentState.max = value.max;
    }

    const activeInput = minInput !== currentState.min
                      ? (employeeCalculation ? `employeeMin${filterType}` : `minInput${filterType}`)
                      : maxInput !== currentState.max
                      ? (employeeCalculation ? `employeeMax${filterType}` : `maxInput${filterType}`)
                      : null;

    activeInput && this.setState({ activeInput });
    this.props.handleSelectorChange(currentState);
  };

  handleChangeComplete = () => {
    const { handleSelectorChange, submitOnChangeComplete } = this.props;
    const { activeInput } = this.state;

    submitOnChangeComplete && handleSelectorChange && handleSelectorChange(null, true);

    if (activeInput) {
      const activeInputField = this[activeInput];
      activeInputField.classList.add('outline-none');
      activeInputField.focus();
    }
  }

  handleFocus = (e) => {
    const value = e.target.value.replace(/\D/g,'');
    value === 0 || value === '0' ? e.target.value = '' : null;
  };

  handleMaxInputFocus = () => this.setState({ maxInputIsFocused: true });

  handleInputClick = (e) => e.target.classList.remove('outline-none');

  render() {
    const {
      maxValue,
      minValue,
      value,
      dimension,
      showRangeWords,
      filterType,
      employeeCalculation,
      showInputLabels,
      showMaxMoreIcon,
    } = this.props;

    const { employeeMax, employeeMin, maxInput, minInput, maxInputIsFocused } = this.state;
    const locale = I18n.locale === 'en' ? 'fr' : I18n.locale;
    const localizedNumberFormatter = (value) => new Intl.NumberFormat(locale).format(value);
    const selectedMaxValue = (!maxInputIsFocused && showMaxMoreIcon && +maxInput === maxValue) ? localizedNumberFormatter(maxInput) + ' +' : localizedNumberFormatter(maxInput);
    const selectedMaxEmployeeValue = (!maxInputIsFocused && showMaxMoreIcon && +employeeMax === Math.round(maxValue / 15)) ? localizedNumberFormatter(employeeMax) + ' +' : localizedNumberFormatter(employeeMax);


    return (
      <div className='input-range__container'>
        {(showRangeWords || showRangeWords === undefined) && (
          <div className='input-range__label'>
            <span>{I18n.t('search_agent.selector.from')}</span>
            <span>{I18n.t('search_agent.selector.to')}</span>
          </div>
        )}

        <InputRange
          maxValue={maxValue}
          minValue={minValue}
          value={value}
          onChange={value => this.handleSlideSelectorChange(value)}
          onChangeComplete={this.handleChangeComplete}
        />

        <form className='input-range__inputs'>

          <div className='form-group'>
            <input
              ref={el => this[employeeCalculation ? `employeeMin${filterType}` : `minInput${filterType}`] = el}
              data-range="min"
              type='text'
              id={employeeCalculation ? `employeeMin` : `minInput`}
              min={minValue}
              max={maxValue}
              value={localizedNumberFormatter(employeeCalculation ? employeeMin : minInput)}
              onBlur={e => this.handleBlur(e, 'min', this.minChange)}
              onChange={this.handleChange}
              className='form-control'
              onKeyPress={e => this.handleKeyPress(e, this.minChange)}
              onFocus={this.handleFocus}
              onClick={(e) => this.handleInputClick(e)}
              inputMode="numeric"
            />
            <span className='input-range__dimension'>{ dimension }</span>
            { showInputLabels &&
              <Fragment>
                <div className="input-range__input-label">
                  { I18n.t('generic.minimum') }
                </div>
                <div className="input-range__input-border" />
              </Fragment>
            }
          </div>

          <div className='form-group'>
            <input
              ref={el => this[employeeCalculation ? `employeeMax${filterType}` : `maxInput${filterType}`] = el}
              data-range="max"
              type='text'
              id={employeeCalculation ? `employeeMax` : `maxInput`}
              min={minValue}
              max={maxValue}
              value={employeeCalculation ? selectedMaxEmployeeValue : selectedMaxValue}
              onBlur={ e => this.handleBlur(e, 'max', this.maxChange)}
              onChange={this.handleChange}
              className='form-control'
              onFocus={this.handleMaxInputFocus}
              onKeyPress={e => this.handleKeyPress(e, this.maxChange)}
              inputMode="numeric"
            />
            <span className='input-range__dimension'>{ dimension }</span>

            { showInputLabels &&
              <Fragment>
                <div className="input-range__input-label">
                  { I18n.t('generic.maximum') }
                </div>
                <div className="input-range__input-border" />
              </Fragment>
            }
          </div>
        </form>
      </div>
    );
  }
}

export default RangeSelector;
