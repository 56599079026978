import React from 'react';

import DropdownPlainSelector from '../../SharedComponents/DropdownPlainSelector';


const TypeSelector = (props) => {
  return (
    <div className='search-agent__config-block'>
      <h2 className={`search-agent__header-block ${props.required ? 'search-agent__required-block' : ''}`}>
        { I18n.t('search_agent.type') }
      </h2>
      { props.showError && <div className='search-agent__error'>{ I18n.t('search_agent.type_error') }</div> }
      <p>{ I18n.t('search_agent.configuration.type.description') }</p>

      <DropdownPlainSelector
        dynamicData={props.dynamicPropertyTypes}
        selectedData={props.selectedPropertyTypes}
        selectAction={props.selectPropertyType}
        placeholder={I18n.t('search_agent.configuration.type.placeholder')}
        selectedCounterPhrase={I18n.t('search_agent.configuration.type.selected_types')}
        isSearchAgent={true}
      />

    </div>
  )
}

export default TypeSelector;
