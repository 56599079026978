import React from 'react';

import { SvgIco } from "../Icons";

import './index.sass';


const AdditionalOption = ({ headline, content, linkContent, buttonColor, link, gridClasses, handleClick, siteKey }) => {

  const handleLinkClick = (e) => {
    handleClick && handleClick(e, link);
  };
  const checkDk = siteKey === 'dk';

  return (
    <div className={`additional-option ${gridClasses}`}>
      <div className="additional-option__wrapper">
        <div className="additional-option__content-wrapper">
          <h2>{ headline }</h2>
          <p>{ content } {checkDk &&
            <a href={link || '/'}>... { I18n.t('generic.read_more') }</a>}</p>
        </div>
        <div className="additional-option__button-wrapper">

          { checkDk ? (
              <a href={link || '/'}
                 className='additional-option__link-button sa-button'
                 target="_blank"
                 onClick={(e) => handleLinkClick(e)}>
                <span>{I18n.t('search_agents.new.standard_search_agent')}</span>
              </a>
            ) :
            (
              <a href={link || '/'}
                 className={`additional-option__link-button additional-option__link-button--${buttonColor}`}
                 target="_blank"
                 onClick={(e) => handleLinkClick(e)}>
                <span>{ linkContent }</span>
                <SvgIco name="keyboard-arrow-right-rounded-ios" size={20} />
              </a>
            )
          }


        </div>
      </div>
    </div>
  )
};


export default React.memo(AdditionalOption);
