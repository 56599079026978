import React, { Component } from 'react';
import queryString from 'query-string';

import CreateSearchAgent from './CreateEditSearchAgent';
import ManageSearchAgents from './ManageSearchAgents';
import ErrorPage from '../ErrorPage';
import { loadTawkTo } from '../TawkTo/TawkTo';


class SearchAgent extends Component {
  state = {
    content: '',
    notificationAction: '',
    editedToken: '',
  };

  componentDidMount() {
    window.addEventListener('popstate', this.handleRouting);
    this.handleRouting();
    loadTawkTo(this.props.siteKey);
  }

  handleShowNotification = (showNotification, action, editedToken) =>
    this.setState({
      notificationAction: showNotification ? action : '',
      editedToken: showNotification && editedToken ? editedToken : '' ,
    });

  handleRequestError = (errorStatus) => {

    const { searchLeasesLink, newSearchAgentLink, signupTeaser, siteName } = this.props;

    const errorProps = {
      paths: {
        root: searchLeasesLink,
        signupTeaser: signupTeaser,
        newSearchAgent: newSearchAgentLink,
      },
      siteName: siteName,
      errorType: errorStatus >= 500 ? 'server_error' : 'client_error',
    };

    this.setState({
      content: <ErrorPage { ...errorProps } />
    });
  };

  handleRedirect = (href) => {
    const isIE = navigator.userAgent.indexOf('MSIE') >= 0 || navigator.userAgent.indexOf('Trident/') >= 0;

    if (isIE) {
      window.location.href = href;
    } else {
      window.history.pushState({}, "", href);
      window.dispatchEvent(new PopStateEvent('popstate'));
    }
  };

  handleRouting = () => {
    const { notificationAction, editedToken } = this.state;
    const pathName = window.location.pathname;
    const page = queryString.parse(window.location.search).page ? parseInt(queryString.parse(window.location.search).page) : 0;

    switch (true) {
      case pathName.includes('new'):
        this.setState({
          content: <CreateSearchAgent {...this.props}
                                      showNotification={this.handleShowNotification}
                                      handleRedirect={this.handleRedirect}
                                      handleRequestError={this.handleRequestError} />
        });
        break;

      case pathName.includes('edit'):
        this.setState({
          content: <CreateSearchAgent {...this.props}
                                      showNotification={this.handleShowNotification}
                                      handleRedirect={this.handleRedirect}
                                      handleRequestError={this.handleRequestError}
                                      isEditMode={true} />
        });
        break;

      case pathName.includes('destroy_confirmation'):
        this.setState({
          content: <CreateSearchAgent {...this.props}
                                      showNotification={this.handleShowNotification}
                                      isDeletingModalOpen={true}
                                      handleRequestError={this.handleRequestError}
                                      handleRedirect={this.handleRedirect}
                                      isEditMode={true} />
        });
        break;

      case !!queryString.parse(window.location.search).token:
        this.setState({
          content: <ManageSearchAgents {...this.props}
                                       showNotification={this.handleShowNotification}
                                       notificationAction={notificationAction}
                                       page={page && page >= 0 ? page : 0}
                                       handleRedirect={this.handleRedirect}
                                       siteKey = {this.props.siteKey}
                                       editedToken={editedToken} />,
        });
        break;

      default:
        this.setState({
          content: <CreateSearchAgent {...this.props}
                                      showNotification={this.handleShowNotification}
                                      handleRedirect={this.handleRedirect}
                                      handleRequestError={this.handleRequestError} />
        });
    }
  };

  render() {


    return (
      <div>
        { this.state.content }
      </div>
    )
  }
}

export default SearchAgent;
