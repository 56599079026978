import React, { Component, Fragment } from 'react';

import { apiProvider } from '../../../api/core';
import SearchAgentHeader from './SearchAgentHeader';
import SearchAgentConfigurations from './SearchAgentConfigurations';

import './index.sass';


class CreateSearchAgent extends Component {

  state = {
    isLoading: true,
    searchAgentParams: null,
    token: '',
  };

  componentDidMount() {

    if (this.props.isEditMode) {
      // TODO: find a better solution to get a token
      const splitedPathname = window.location.pathname.split('/');
      const token = splitedPathname[splitedPathname.length - 2];
      const url = `/lease/api/frontend/search_agents/${token}/edit`;

      apiProvider.getAll(url, true)
                 .then(response => {
                   response.error
                     ? this.props.handleRequestError(response.status)
                     : this.setState({ searchAgentParams: { ...response }, isLoading: false, token });
                 });
    } else {
      this.setState({ isLoading: false })
    }

    window.scrollTo(0, 0);
  }

  kinds(isSaleAgent, kindsBySectionsMap) {
    const { searchAgentParams } = this.state;
    let kinds = isSaleAgent ? kindsBySectionsMap["sale"] : kindsBySectionsMap["lease"];
    if (searchAgentParams) {
      kinds = kindsBySectionsMap[searchAgentParams["sections"][0]] || kindsBySectionsMap["sale"];
    }
    return kinds;
  }

  render() {
    const {
      isEditMode,
      isDeletingModalOpen,
      endPoints,  //n
      siteKey,
      kindsBySectionsMap,
      sections,
      locale,
      allCountries,
      showNotification,
      handleRedirect,
      searchLeasesLink,
      isSaleAgent,
      keywords,
      preselectedPostalDistricts,
    } = this.props;

    const { searchAgentParams, isLoading, token } = this.state;
    const spinner =
      <div className="spinner-wrapper">
        <div className="smoothie-spinner" />
      </div>;

    return (
      <div className='search-agent search-agent__page-wrapper'>
        <div>
          { isLoading ? spinner :
            <Fragment>
              <SearchAgentHeader
                token={token}
                locale={locale}
                searchAgentParams={searchAgentParams}
                searchLeasesLink={searchLeasesLink}
                showNotification={showNotification}
                handleRedirect={handleRedirect}
                isEditMode={isEditMode} />
              <SearchAgentConfigurations
                preselectedPostalDistricts={preselectedPostalDistricts}
                keywords={keywords}
                endPoints={endPoints}
                isEditMode={isEditMode}
                isDeletingModalOpen={isDeletingModalOpen}
                isSaleAgent={isSaleAgent}
                siteKey={siteKey}
                types={this.kinds(isSaleAgent, kindsBySectionsMap)}
                sections={sections}
                locale={locale}
                allCountries={allCountries}
                searchAgentParams={searchAgentParams}
                showNotification={showNotification}
                handleRedirect={handleRedirect}
                searchLeasesLink={searchLeasesLink}
                token={token} />
            </Fragment>
          }
        </div>
      </div>
    )
  }
}

export default CreateSearchAgent;
