import React from 'react';
import DropdownPlainSelector from '../../SharedComponents/DropdownPlainSelector';

const SectionSelector = (props) => {
  return (
    <div className='search-agent__config-block'>
      <h2 className={`search-agent__header-block ${props.required ? 'search-agent__required-block' : ''}`}>
        { I18n.t('search_agent.section') }
      </h2>
      { props.showError && <div className='search-agent__error'>{ I18n.t('search_agent.section_error') }</div> }
      <p>{ I18n.t('search_agent.configuration.section.description') }</p>

      <DropdownPlainSelector
        dynamicData={props.dynamicSections}
        selectedData={props.selectedSections}
        selectAction={props.selectSection}
        placeholder={I18n.t('search_agent.configuration.section.placeholder')}
        selectedCounterPhrase={I18n.t('search_agent.configuration.section.selected_sections')}
      />
    </div>
  )
};

export default SectionSelector;
