import React, { Component } from 'react';
import Yup from 'yup';
import { withFormik } from 'formik';

import Client from '../../../utils/Client';
import { validationPatterns } from '../../../utils/validationPatterns';

import './ContactForm.sass';


class Form  extends Component {
  state = {
    fieldValuesStore: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    JSON.stringify(prevProps.errors) !== JSON.stringify(this.props.errors) && this.props.updateErrors(this.props.errors);
  }

  handleFieldBlur = (e) => {
    this.props.handleBlur(e);
    this.props.updateUser(this.props.values);
  };


  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      required,
      showError,
      handleFormUpdate,
      setFieldValue,
    } = this.props;


    return (
      <div className='search-agent__config-block contact-form'>
        <h2 className={`search-agent__header-block ${required ? 'search-agent__required-block' : ''}`}>{I18n.t('search_agent.contact_information')}</h2>
        {showError && <div className='search-agent__error'>{I18n.t('search_agent.contact_information_error')}</div>}
        <p>{ I18n.t('search_agent.configuration.contact_information.description') }</p>

        <form className="search-agent" noValidate >
          <div className={`form-group search-agent__form-group ${touched.name ? (errors.name ? 'search-agent-error' : null) : null }`} >
            <label className="search-agent__label" htmlFor="name">
              <input
                id='name'
                type='text'
                value={values.name}
                onChange={ e => {
                  handleChange(e);
                  handleFormUpdate && handleFormUpdate();
                }}
                onBlur={this.handleFieldBlur}
                className={`search-agent__input ${values.name && 'active'} `} />
              <span className={`sign-up-form__floating-label ${values.name && 'active'}`}>
                { I18n.t('search_agent.name') }*
              </span>
            </label>
            <div className="validation-message">
              {touched.name && errors.name ? errors.name : null}
            </div>
          </div>

          <div className={`form-group search-agent__form-group ${touched.email ? (errors.email ? 'search-agent-error' : null) : null }`}>
            <label className="search-agent__label" htmlFor="email">
              <input
                id='email'
                type='email'
                value={values.email}
                onChange={ e => {
                  handleChange(e);
                  handleFormUpdate && handleFormUpdate();
                }}
                onBlur={this.handleFieldBlur}
                className={`search-agent__input ${values.email && 'active'} `} />
              <span className={`sign-up-form__floating-label ${values.email && 'active'}`}>
                { I18n.t('search_agent.email') }*
              </span>
              </label>
              <div className="validation-message">
                {touched.email && errors.email ? errors.email : null}
              </div>
          </div>

          <div className={`form-group search-agent__form-group ${touched.phone ? (errors.phone ? 'search-agent-error' : null) : null }`}>
            <label className="search-agent__label" htmlFor="phone">
              <input
                id='phone'
                type='tel'
                value={values.phone}
                onChange={ e => {
                  setFieldValue('phone', e.target.value.trimStart().replace(/\s\s+/g, ' '));
                  handleFormUpdate && handleFormUpdate();
                }}
                onBlur={this.handleFieldBlur}
                className={`search-agent__input ${values.phone && 'active'}`} />
              <span className={`sign-up-form__floating-label ${values.phone && 'active'}`}>
                { I18n.t('search_agent.phone') }*
              </span>
            </label>
            <div className="validation-message">
              {touched.phone && errors.phone ? errors.phone : null}
            </div>
          </div>

          <div className={`form-group search-agent__form-group ${touched.company ? (errors.company ? 'search-agent-error' : null) : null }`}>
            <label className="search-agent__label" htmlFor="company">
              <input
                id='company'
                type='text'
                value={values.company}
                onChange={ e => {
                  setFieldValue('company', e.target.value.trimStart().replace(/\s\s+/g, ' '));
                  handleFormUpdate && handleFormUpdate();
                }}
                onBlur={this.handleFieldBlur}
                className={`search-agent__input ${values.company && 'active'}`} />
              <span className={`sign-up-form__floating-label ${values.company && 'active'}`}>
                { I18n.t('search_agent.company') }*
              </span>
            </label>
            <div className="validation-message">
              {touched.company && errors.company ? errors.company : null}
            </div>
          </div>
        </form>
      </div>
    )
  }
}


const { name, phone, email, company } = Client.getClientInfo();

const {
  nameI18n,
  emailI18n,
  companyI18n,
  phoneI18n,
} = Client.getI18nAttrNames();

const i18nRequiredKey = 'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nMinNumberKey = 'apps.lb_showroom.detailed.order_form.validation.min_number';
const i18nSymbolsLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 254 });
const i18nSymbolsEmailLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 100 });
const i18nAtLeast8Digits = 'apps.lb_showroom.detailed.order_form.validation.at_least_8_digits';

const ContactForm = withFormik({
  mapPropsToValues: (bag) => ({
    email: bag.user.email || email,
    name: bag.user.name || name,
    company: bag.user.company || company,
    phone: bag.user.phone || phone,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.name, I18n.t("apps.lb_showroom.misc.scoped_form.name.invalid"))
      .required(I18n.t(i18nRequiredKey, { field: nameI18n })),
    email: Yup.string()
      .matches(validationPatterns.symbolsEmailLimit, i18nSymbolsEmailLimitErrorMessage)
      .email(I18n.t('apps.lb_showroom.detailed.order_form.validation.wrong_email'))
      .required(I18n.t(i18nRequiredKey, { field: emailI18n })),
    company: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.company, I18n.t("apps.lb_showroom.misc.scoped_form.company.invalid"))
      .required(I18n.t(i18nRequiredKey, { field: companyI18n })),
    phone: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .min(8, I18n.t(i18nMinNumberKey, { field: phoneI18n }))
      .matches(validationPatterns.phone, I18n.t('apps.lb_showroom.misc.scoped_form.phone.invalid'))
      .test('minDigitsValidation', I18n.t(i18nAtLeast8Digits), function(value) {
        const { path, createError } = this;
        const phoneIsValid = value && value.replace(/[+\-\s()]/g, '').length >= 8;
        return phoneIsValid || !value || createError({ path, message: I18n.t(i18nAtLeast8Digits) });
      })
      .required(I18n.t(i18nRequiredKey, { field: phoneI18n }))
  }),
  handleSubmit: (values, bag) => {
  },
  displayName: 'ContactForm', // helps with React DevTools
})(Form);

export default ContactForm;
